import { useAuth0 } from '@auth0/auth0-react';
import { Notifications } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import {
  AppBar,
  Badge,
  Box,
  Button,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Link as MuiLink,
  Popover,
  Stack,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import mixpanel from 'mixpanel-browser';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { Notification } from '../../@types/notifications';
import { SearchContent } from '../../@types/search';
import { ReactComponent as LogoutIcon } from '../../assets/icons/logoutIcon.svg';
import { ReactComponent as PlansIcon } from '../../assets/icons/plansIcon.svg';
import { NotificationItem } from '../../components/NotificationItem';
import SearchContentList from '../../components/SearchContentList';
import notificationService from '../../services/notificationService';
import searchService from '../../services/searchService';
import trackEvent from '../../services/trackService';
import { useSidebarStore } from '../../store/sidebar.store';
import './simpleBar.css';
import { useUserStore } from '../../store/user.store';

export function NavBar() {
  const navigate = useNavigate();
  const { loginWithRedirect, logout, user } = useAuth0();
  const [searching, setSearching] = useState(false);
  const [search, setSearch] = useState('');
  const [searchResults, setSearchResults] = useState<SearchContent[]>([]);
  const searchBarRef = useRef<HTMLDivElement>(null);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [notCheckedNotificationLength, setNotCheckedNotificationLength] = useState<number>(0);

  const [notificationsButton, setNotificationsButton] = useState<HTMLButtonElement | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const isActiveNotifications = notifications.some(n => !n.checked);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const toggleSidebarMobile = useSidebarStore(state => state.toggleSidebar);

  const open = Boolean(anchorEl);

  const userInfo = useUserStore(state => state.user);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (user) {
      mixpanel.identify(user.sub);
      mixpanel.people.set({ email: user.email, name: user.name || '' });
      if (user['new-signed-up'] && !window.localStorage.getItem(user.sub + '-new-signed-up')) {
        trackEvent('sign-up', {});
        window.localStorage.setItem(user.sub + '-new-signed-up', 'true');
      }
      trackEvent('login', {});
    } else {
      trackEvent('anonymous-login', {});
    }
  }, [user]);
  const getNotifications = async () => {
    await notificationService.getNotifications().then(data => {
      setNotifications(data.notifications as Notification[]);
      setNotCheckedNotificationLength(data.notCheckedNotificationLength);
    });
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (user) {
      intervalId = setInterval(getNotifications, 5000);
    }

    return () => clearInterval(intervalId);
  }, [user]);

  useEffect(() => {
    if (notificationsButton) {
      isActiveNotifications && notificationService.checkNotification();
      isActiveNotifications && setNotifications(notifications => notifications.map(notification => ({ ...notification, checked: true })));
      isActiveNotifications && setNotCheckedNotificationLength(0);
    }
  }, [notificationsButton, notifications]);

  const handleOpenNotifications = (event: React.MouseEvent<HTMLButtonElement>) => {
    setNotificationsButton(event.currentTarget);
  };

  const handleCloseNotifications = () => {
    setNotificationsButton(null);
  };

  const handleLogout = async () => {
    await logout({ logoutParams: { returnTo: window.location.origin } });
    handleClose();
  };

  const onLogoClick = () => {
    user ? navigate('/dashboard') : loginWithRedirect();
  };

  const handleClear = () => {
    setSearch('');
    setSearching(false);
  };

  async function handleSearch(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    try {
      setSearch(e.target.value);
      setSearching(true);
    } catch (error) {
      console.error('Error during search:', error);
      setSearching(false);
    }
  }

  useEffect(() => {
    const fetchSearch = async () => {
      try {
        const results = await searchService.getSearchContent();
        setSearchResults(results);
      } catch (error) {
        console.error('Error during search:', error);
        setSearching(false);
      }
    };

    fetchSearch();
  }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (searchBarRef.current && !searchBarRef.current.contains(event.target as Node)) {
        setSearching(false);
      }
    }

    if (searching) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searching]);

  return (
    <AppBar
      sx={{
        minHeight: '56px',
        height: '56px',
        '& .MuiToolbar-root': {
          minHeight: '56px'
        }
      }}
      position={isMobile ? 'sticky' : 'static'}
      component="nav"
    >
      <Toolbar sx={{ minHeight: '56px', height: '56px' }}>
        <Grid
          container
          py={2}
          spacing={isMobile ? 0 : 2}
          direction="row"
          alignItems="center"
          justifyContent={isMobile ? 'unset' : 'space-between'}
        >
          {isMobile && (
            <IconButton size="large" color="inherit" aria-label="menu" onClick={toggleSidebarMobile}>
              <MenuIcon />
            </IconButton>
          )}
          <Grid item xs={3}>
            <Button onClick={onLogoClick}>
              <img src="/logo.png" alt="logo" width={108} height={24} />
            </Button>
          </Grid>
          {user && !isMobile && (
            <Grid item xs sx={{ position: 'relative' }}>
              <TextField
                sx={{
                  maxWidth: '351px',
                  '& .MuiOutlinedInput-root': {
                    fontSize: '14px',
                    '& fieldset': {
                      border: 'none'
                    }
                  }
                }}
                fullWidth
                placeholder="Search by song or stem"
                variant="outlined"
                size="small"
                value={search}
                InputProps={{
                  startAdornment: <SearchIcon sx={{ mr: 1 }} />,
                  endAdornment: (
                    <IconButton>
                      <CloseIcon onClick={handleClear} />
                    </IconButton>
                  )
                }}
                onChange={handleSearch}
              />
              {searching && (
                <Box
                  ref={searchBarRef}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'absolute',
                    background: '#353535',
                    padding: '1rem',
                    paddingTop: '0.5em',
                    paddingBottom: '1rem',
                    justifyContent: 'space-evenly',
                    width: '100%',
                    maxWidth: '351px',
                    zIndex: '9999',
                    top: 65
                  }}
                >
                  <SearchContentList searchKey={search} content={searchResults} />
                </Box>
              )}
            </Grid>
          )}

          <Grid item xs={'auto'}>
            <Stack direction="row" spacing={2} alignItems="center">
              {user && !isMobile && (
                <>
                  <IconButton
                    onClick={handleOpenNotifications}
                    sx={{
                      '&.MuiIconButton-root': {
                        padding: '6px'
                      }
                    }}
                  >
                    <Badge
                      badgeContent={notCheckedNotificationLength}
                      invisible={!Boolean(notCheckedNotificationLength)}
                      aria-describedby="notifications"
                      sx={{
                        '& .MuiBadge-badge': {
                          backgroundColor: '#FF0000',
                          minWidth: '14px',
                          width: '14px',
                          height: '14px',
                          right: '7px',
                          top: '5px'
                        }
                      }}
                    >
                      <Notifications color="action" />
                    </Badge>
                  </IconButton>
                  <Popover
                    id="notifications"
                    open={Boolean(notificationsButton)}
                    anchorEl={notificationsButton}
                    onClose={handleCloseNotifications}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    PaperProps={{ sx: { backgroundColor: '#000', backgroundImage: 'none', width: '570px', px: 1.5, pt: 1.5, pb: 2.5 } }}
                  >
                    <SimpleBar style={{ minHeight: '36px', maxHeight: '180px' }}>
                      <Stack direction={'column'} rowGap={1.5}>
                        {!notifications?.length && (
                          <Stack alignItems={'center'} justifyContent={'center'}>
                            <Typography>You haven't any notifications</Typography>
                          </Stack>
                        )}
                        {notifications.map(notification => (
                          <NotificationItem
                            key={notification.id}
                            notificationType={notification.actionType}
                            songName={notification.songName}
                            senderName={notification.senderName ?? notification.senderEmail}
                            updatedAt={notification.updatedAt}
                            folderName={notification.folderName}
                          />
                        ))}
                      </Stack>
                    </SimpleBar>
                  </Popover>
                </>
              )}
              {user && !isMobile && (
                <>
                  <MuiLink component={Link} to="/faqs" fontFamily={'sans-serif'}>
                    How it works
                  </MuiLink>
                  <Box sx={{ width: '1px', height: '24px', backgroundColor: '#FFFFFF' }} />
                  {!!userInfo?.organizationRole?.length && userInfo?.organizationRole !== 'Member' && (
                    <>
                      <MuiLink component={Link} to="/organization" fontFamily={'sans-serif'}>
                        Organization
                      </MuiLink>
                      <Box sx={{ width: '1px', height: '24px', backgroundColor: '#FFFFFF' }} />
                    </>
                  )}
                  <MuiLink component={Link} to="/plans" fontFamily={'sans-serif'}>
                    Plans
                  </MuiLink>
                  <Box sx={{ width: '1px', height: '24px', backgroundColor: '#FFFFFF' }} />
                  <MuiLink component={Link} to="/dashboard" fontFamily={'sans-serif'}>
                    Upload
                  </MuiLink>
                </>
              )}
              {!user && !isMobile && (
                <>
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{
                      color: 'white',
                      borderColor: 'white',
                      '&:hover': {
                        borderColor: 'white'
                      }
                    }}
                    onClick={event => {
                      event.preventDefault();

                      navigate('/faqs');
                    }}
                  >
                    How it works
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{
                      color: 'white',
                      borderColor: 'white',
                      '&:hover': {
                        borderColor: 'white'
                      }
                    }}
                    onClick={event => {
                      event.preventDefault();

                      navigate('/plans');
                    }}
                  >
                    Plans
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{
                      color: 'white',
                      borderColor: 'white',
                      '&:hover': {
                        borderColor: 'white'
                      }
                    }}
                    onClick={() => loginWithRedirect()}
                  >
                    Upload
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{
                      color: 'white',
                      borderColor: 'white',
                      '&:hover': {
                        borderColor: 'white'
                      }
                    }}
                    onClick={() =>
                      loginWithRedirect({
                        authorizationParams: {
                          redirectUri: window.location.origin,
                          appState: {
                            returnTo: '/dashboard'
                          }
                        }
                      })
                    }
                  >
                    Log in/Sign up
                  </Button>
                </>
              )}
              {user && !isMobile && (
                <>
                  <Box sx={{ width: '1px', height: '24px', backgroundColor: '#FFFFFF' }} />
                  <Typography variant="body2" onClick={handleClick} style={{ cursor: 'pointer' }}>
                    {user.name}
                  </Typography>
                  <Menu
                    disableAutoFocusItem
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      style: {
                        width: '170px',
                        backgroundColor: '#000000 !important'
                      }
                    }}
                    slotProps={{
                      paper: {
                        style: {
                          marginTop: '12px',
                          marginLeft: '-10px'
                        }
                      }
                    }}
                  >
                    <MenuItem
                      component="a"
                      href="https://app.termly.io/policy-viewer/policy.html?policyUUID=c1db8aec-c425-452b-bf86-382d16418417"
                      onClick={handleClose}
                      sx={{
                        backgroundColor: '#000000',
                        color: '#FFFFFF',
                        height: '35px',
                        '&:hover': {
                          backgroundColor: '#1d1d1d'
                        }
                      }}
                    >
                      <Typography sx={{ fontSize: '14px' }}>Privacy Policy</Typography>
                    </MenuItem>
                    <MenuItem
                      component="a"
                      href="https://app.termly.io/policy-viewer/policy.html?policyUUID=dfc29e66-843c-472b-8931-bc952f1e138c"
                      onClick={handleClose}
                      sx={{
                        backgroundColor: '#000000',
                        color: '#FFFFFF',
                        height: '35px',
                        '&:hover': {
                          backgroundColor: '#1d1d1d'
                        }
                      }}
                    >
                      <Typography sx={{ fontSize: '14px' }}>Terms and Conditions</Typography>
                    </MenuItem>

                    <MenuItem
                      onClick={handleLogout}
                      sx={{
                        backgroundColor: '#000000',
                        color: 'white',
                        height: '35px',
                        '&:hover': {
                          backgroundColor: '#1d1d1d'
                        }
                      }}
                    >
                      <ListItemIcon style={{ minWidth: 'unset', marginRight: '4px' }}>
                        <LogoutIcon />
                      </ListItemIcon>
                      <Typography sx={{ fontSize: '14px' }}>Log out</Typography>
                    </MenuItem>
                  </Menu>
                </>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
