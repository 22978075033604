import { useAuth0 } from '@auth0/auth0-react';
import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTokenStore } from '../store/token.store';

type AuthGuardProps = {
  children: ReactNode;
};

export function AuthGuard({ children }: AuthGuardProps) {
  const isTokenLoadingFinished = useTokenStore(state => state.isFinished);
  const isUserExist = useTokenStore(state => state.isUserExist);

  return isTokenLoadingFinished && isUserExist ? <>{children}</> : null;
}
