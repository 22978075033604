import { Song } from './songs';

export enum AccessType {
  CanDownload = 'Can download',
  CanView = 'Can view',
  CanEdit = 'Can edit'
}

export enum SharedUserRoles {
  Editor = 'EDITOR',
  Viewer = 'VIEWER',
  Downloader = 'CO_OWNER',
  Owner = 'OWNER'
}

export type SharedUserInfo = {
  email: string;
  accessType: string;
};

export type SharedUserInfoUploadPage = {
  email: string;
  accessType: string;
};

export type AutofillEmails = {
  emails: string[];
};

type BaseShareByInviteRequest = {
  songId: string;
  folderId: string;
  type: string;
};

export type ShareByInviteRequest = BaseShareByInviteRequest & {
  email: string;
};

export type ShareFolderType = Omit<BaseShareByInviteRequest, 'songId'> & {
  email: string;
};

export type BulkShareByInviteRequest = Omit<BaseShareByInviteRequest, 'type'> & {
  usersData: { email: string; accessType: string }[];
};

export type SharedFolderItem = {
  folderId: string;
  folderName: string;
  songs: Song[];
};
