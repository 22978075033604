import { Box, Grid, List, ListItem, ListItemText, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';

import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

export function FAQs() {
  return (
    <Box
      height={1}
      sx={{
        overflow: 'hidden'
      }}
    >
      <SimpleBar style={{ height: `100%`, maxHeight: `calc(100dvh - 56px - 16px)`, overflowY: 'auto', overflowX: 'hidden' }}>
        <Grid container flexGrow={1} height={1} direction={'column'} gap={1} flexWrap={'nowrap'}>
          <Grid item sx={{ background: 'rgba(21, 21, 21, 1)', borderRadius: 1 }} pb={4.5} px={3} pt={3}>
            <Grid container spacing={6}>
              <Grid
                item
                xs={5.5}
                sx={{
                  position: 'relative',
                  zIndex: 1,
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    background: "url('/assets/FAQs.svg') no-repeat center bottom",
                    backgroundSize: 'contain',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: -36,
                    zIndex: -1
                  }
                }}
              >
                <Typography fontSize={20} lineHeight="24px" fontWeight={700}>
                  How it Works
                </Typography>
                <Grid container direction="column" rowGap={14.5} borderRadius={2} pt={7}>
                  <Grid
                    item
                    py={2}
                    px={2.5}
                    sx={{
                      position: 'relative',
                      background: 'rgba(217, 217, 217, 0.05)',
                      borderRadius: 2,
                      backdropFilter: 'blur(7.5px)',
                      overflow: 'hidden',
                      zIndex: 1,
                      '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: -1,
                        borderRadius: 2,
                        padding: '1px',
                        background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.2) 80%, rgba(0, 0, 0, 0.8) 100%)',
                        WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                        mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                        WebkitMaskComposite: 'xor',
                        maskComposite: 'exclude'
                      }
                    }}
                  >
                    <Typography fontWeight={700} fontSize={18} lineHeight="30px">
                      Drag and drop stems or other music files into the Upload page.
                    </Typography>
                    <Typography fontWeight={500} fontSize={15} lineHeight="30px" color={'#B9B9B9'}>
                      DEMO supports WAV, MP3, AIFF, folders and ZIP folders containing sub-folders.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    pt={2.5}
                    pb={3}
                    px={2.5}
                    sx={{
                      position: 'relative',
                      background: 'rgba(217, 217, 217, 0.05)',
                      borderRadius: 2,
                      backdropFilter: 'blur(7.5px)',
                      overflow: 'hidden',
                      zIndex: 1,
                      '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: -1,
                        borderRadius: 2,
                        padding: '1px',
                        background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.2) 80%, rgba(0, 0, 0, 0.8) 100%)',
                        WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                        mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                        WebkitMaskComposite: 'xor',
                        maskComposite: 'exclude'
                      }
                    }}
                  >
                    <Typography fontWeight={700} fontSize={18} lineHeight="30px">
                      Share by entering an email, select the desired permission level:
                    </Typography>
                    <List sx={{ listStyleType: 'disc', p: 0, pl: 14, color: '#B9B9B9' }}>
                      <ListItem sx={{ display: 'list-item', p: 0 }}>
                        <ListItemText sx={{ fontWeight: 500, fontSize: 16, fontFamily: 'DM Sans', '& span': { color: '#B9B9B9' } }}>
                          Can Download
                        </ListItemText>
                      </ListItem>
                      <ListItem sx={{ display: 'list-item', p: 0 }}>
                        <ListItemText sx={{ fontWeight: 500, fontSize: 16, fontFamily: 'DM Sans', '& span': { color: '#B9B9B9' } }}>
                          Can View - no downloading
                        </ListItemText>
                      </ListItem>
                      <ListItem sx={{ display: 'list-item', p: 0 }}>
                        <ListItemText sx={{ fontWeight: 500, fontSize: 16, fontFamily: 'DM Sans', '& span': { color: '#B9B9B9' } }}>
                          Can Edit - can add/delete files
                        </ListItemText>
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid
                    item
                    pt={2.75}
                    pb={2.5}
                    px={2.5}
                    sx={{
                      position: 'relative',
                      background: 'rgba(217, 217, 217, 0.05)',
                      borderRadius: 2,
                      backdropFilter: 'blur(7.5px)',
                      overflow: 'hidden',
                      zIndex: 1,
                      '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: -1,
                        borderRadius: 2,
                        padding: '1px',
                        background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.2) 80%, rgba(0, 0, 0, 0.8) 100%)',
                        WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                        mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                        WebkitMaskComposite: 'xor',
                        maskComposite: 'exclude'
                      }
                    }}
                  >
                    <Typography fontWeight={700} fontSize={18} lineHeight="30px">
                      Play, solo, & mute stems to see how your stems work together.
                    </Typography>
                    <Typography fontWeight={500} fontSize={15} lineHeight="30px" color={'#B9B9B9'}>
                      Perfect for showing off your work.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6.5}>
                <Grid container direction="column" rowGap={8.75}>
                  <Grid item pt={10}>
                    <iframe
                      width="100%"
                      height="412"
                      src="https://www.youtube.com/embed/r-yJo8kwgAo?si=-y0HG_FII0EKeaax"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                    ></iframe>
                  </Grid>
                  <Grid item>
                    <iframe
                      width="100%"
                      height="412"
                      src="https://www.youtube.com/embed/A29lYTjHhLU?si=naHEqmL0r4ad_ZAt"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                    ></iframe>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ background: 'rgba(21, 21, 21, 1)', borderRadius: 1 }} pb={4.5} px={3} pt={3}>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={4}>
                <Grid container direction="column" gap={8}>
                  <Stack
                    direction="row"
                    width={{ xs: '100%', sm: '80%' }}
                    height={'51px'}
                    sx={{
                      background: 'rgba(255, 255, 255, 1)',
                      boxShadow: '0px 0px 16.6px 7px rgba(255, 255, 255, 0.25)',
                      borderRadius: 9
                    }}
                    py={1}
                    px={1.25}
                    alignItems={'center'}
                  >
                    <img src="/assets/appFAQs.png" alt="FAQs book" height={37} width={37} />
                    <Typography color="rgba(0, 0, 0, 1)" fontSize={18} fontWeight={600} ml={6}>
                      Other Features
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={8} pt={2.5}>
                <Typography fontSize={18} fontWeight={700} lineHeight={'30px'}>
                  Metadata: Add and view essential metadata like BPM, key, and more.
                </Typography>
                <Typography fontSize={16} fontWeight={500} lineHeight={'30px'} color={'#B9B9B9'} mb={6}>
                  Use DEMO to read metadata and embed new metadata into your audio files.
                </Typography>

                <Typography fontSize={18} fontWeight={700} lineHeight={'30px'}>
                  Commenting: <span style={{ fontSize: 16, fontWeight: 500, lineHeight: '30px', color: '#B9B9B9' }}>(Coming Soon) </span>
                  Add time-coded comments on tracks in the DEMO interface.
                </Typography>
                <Typography fontSize={16} fontWeight={500} lineHeight={'30px'} color={'#B9B9B9'}>
                  This way, you don’t have to track edits and reviews on a separate platform.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ background: 'rgba(21, 21, 21, 1)', borderRadius: 1 }} pb={4.5} px={3} pt={3}>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={4}>
                <Grid container direction="column" gap={8}>
                  <Stack
                    direction="row"
                    width={{ xs: '100%', sm: '80%' }}
                    height={'51px'}
                    sx={{
                      background: 'rgba(255, 255, 255, 1)',
                      boxShadow: '0px 0px 16.6px 7px rgba(255, 255, 255, 0.25)',
                      borderRadius: 9
                    }}
                    py={1}
                    px={1.25}
                    alignItems={'center'}
                  >
                    <img src="/assets/chatFAQs.png" alt="FAQs book" height={37} width={37} />
                    <Typography color="rgba(0, 0, 0, 1)" fontSize={18} fontWeight={600} ml={6}>
                      FAQs
                    </Typography>
                  </Stack>
                  <Stack display={{ xs: 'none', sm: 'block' }}>
                    <iframe
                      width="100%"
                      height="530"
                      src="https://www.youtube.com/embed/1mUDXWtmQSU"
                      title="September 20, 2024"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                    ></iframe>
                  </Stack>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={8} pt={2.5}>
                <Typography fontSize={18} fontWeight={700} lineHeight={'30px'}>
                  What is DEMO?
                </Typography>
                <Typography fontSize={16} fontWeight={500} lineHeight={'30px'} color={'#B9B9B9'} mb={6}>
                  DEMO is the quickest, most intuitive way to share music. Composers/producers use DEMO to deliver stems and albums to
                  clients. Clients prefer DEMO to other platforms since they can interact with files without needing to download them.
                </Typography>

                <Typography fontSize={18} fontWeight={700} lineHeight={'30px'}>
                  What’s different about DEMO?
                </Typography>
                <Typography fontSize={16} fontWeight={500} lineHeight={'30px'} color={'#B9B9B9'} mb={6}>
                  DEMO allows you and collaborators to audition, solo and mute tracks - like a digital audio workstation (DAW). By the time
                  other platforms are even half way done uploading, you’ll have already previewed and shared your stems.
                </Typography>

                <Typography fontSize={18} fontWeight={700} lineHeight={'30px'}>
                  Do links expire?
                </Typography>
                <Typography fontSize={16} fontWeight={500} lineHeight={'30px'} color={'#B9B9B9'} mb={6}>
                  No, shared links do not expire if you log into your DEMO account, ensuring ongoing access for you and your collaborators.
                </Typography>

                <Typography fontSize={18} fontWeight={700} lineHeight={'30px'}>
                  Is there a limit to the number of files I can share?
                </Typography>
                <Typography fontSize={16} fontWeight={500} lineHeight={'30px'} color={'#B9B9B9'} mb={6}>
                  No, however, storage capacity depends on your subscription plan. Your first 2 GB are on us.
                </Typography>

                <Typography fontSize={18} fontWeight={700} lineHeight={'30px'}>
                  How do I get started?
                </Typography>
                <Typography fontSize={16} fontWeight={500} color={'#B9B9B9'} lineHeight={'30px'}>
                  Simply sign up with a user-name and password, verify your email, and begin uploading and sharing your music. It’s that
                  easy!
                </Typography>
              </Grid>

              <Grid item xs={12} display={{ xs: 'block', sm: 'none' }}>
                <iframe
                  width="100%"
                  height="530"
                  src="https://www.youtube.com/embed/1mUDXWtmQSU"
                  title="September 20, 2024"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ background: 'rgba(21, 21, 21, 1)', borderRadius: 1 }} pb={4.5} px={3} pt={3}>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={4}>
                <Grid container direction="column" gap={8}>
                  <Stack
                    direction="row"
                    width={{ xs: '100%', sm: '80%' }}
                    height={'51px'}
                    sx={{
                      background: 'rgba(255, 255, 255, 1)',
                      boxShadow: '0px 0px 16.6px 7px rgba(255, 255, 255, 0.25)',
                      borderRadius: 9
                    }}
                    py={1}
                    px={1.25}
                    alignItems={'center'}
                  >
                    <img src="/assets/emailFAQs.png" alt="Email us" height={37} width={37} />
                    <Typography color="rgba(0, 0, 0, 1)" fontSize={18} fontWeight={600} ml={6}>
                      Get in touch
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={8} pt={2.5}>
                <Typography fontSize={18} fontWeight={700} lineHeight={'30px'}>
                  Questions, need help, feature requests, bug reports, just want to say hi?
                </Typography>
                <Typography fontSize={16} fontWeight={500} lineHeight={'30px'} color={'#B9B9B9'}>
                  Email us: support@demo.studio
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SimpleBar>
    </Box>
  );
}
