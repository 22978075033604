import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import SimpleBar from 'simplebar-react';
import { MetadataBitDepthValues, MetadataSampleRateValues } from '../@types/songMetadata';
import { ReactComponent as ArrowDown } from '../assets/icons/arrow_down.svg';
import { SongBPM } from '../sections/mainSongPage/SongBPM';
import { SongKey } from '../sections/mainSongPage/SongKey';
import songMetadataService from '../services/songMetadataService';
import { useSongSelector } from '../store/selectors/song.selector';
import { MetadataBoxWrapper } from './MetadataBoxWrapper';

type MetadataSidebarProps = {
  closeSidebar: () => void;
  canEdit?: boolean;
  isSidebarOpened: boolean;
  playlist: any;
};

export function MetadataSidebar({ closeSidebar, canEdit = false, isSidebarOpened, playlist }: MetadataSidebarProps) {
  const { enqueueSnackbar } = useSnackbar();
  const containerRef = useRef<HTMLDivElement>();
  const metadataContainerRef = useRef<HTMLDivElement>();
  const [renderScroll, setRenderScroll] = useState(false);
  const {
    metadata,
    currentVersion,
    addAlbumTitle,
    deleteAlbumTitle,
    addGenre,
    deleteGenre,
    addArtist,
    deleteArtist,
    addISRCCode,
    deleteISRCCode,
    addUPCCode,
    deleteUPCCode,
    addComposer,
    deleteComposer,
    addPublisher,
    deletePublisher,
    addEditLyrics,
    changeSampleRate,
    changeBitDepth
  } = useSongSelector();
  const [isNewAlbumTitle, setIsNewAlbumTitle] = useState(false);
  const [isNewGenre, setIsNewGenre] = useState(false);
  const [isNewArtist, setIsNewArtist] = useState(false);
  const [isNewComposer, setIsNewComposer] = useState(false);
  const [isNewPublisher, setIsNewPublisher] = useState(false);
  const [isNewISRCCode, setIsNewISRCCode] = useState(false);
  const [isNewUPCCode, setIsNewUPCCode] = useState(false);

  const [isSavingMetadata, setIsSavingMetadata] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [lyrics, setLyrics] = useState(metadata?.lyrics?.at(0)?.lyrics ?? '');
  const [oldLyrics, setOldLyrics] = useState(metadata?.lyrics?.at(0)?.lyrics ?? '');

  const albumTitleInput = useRef<null | HTMLInputElement>(null);
  const genreInput = useRef<null | HTMLInputElement>(null);
  const artistInput = useRef<null | HTMLInputElement>(null);

  const composerInput = useRef<null | HTMLInputElement>(null);
  const composerOwnershipRateInput = useRef<null | HTMLInputElement>(null);

  const publisherInput = useRef<null | HTMLInputElement>(null);
  const publisherOwnershipRateInput = useRef<null | HTMLInputElement>(null);

  const ISRCCodeInput = useRef<null | HTMLInputElement>(null);
  const UPCCodeInput = useRef<null | HTMLInputElement>(null);

  useEffect(() => {
    setLyrics(metadata?.lyrics?.at(0)?.lyrics ?? '');
    setOldLyrics(metadata?.lyrics?.at(0)?.lyrics ?? '');
  }, [metadata]);

  useEffect(() => {
    if (containerRef && metadataContainerRef) {
      setRenderScroll(true);
    }
  }, [containerRef, metadataContainerRef]);

  useEffect(() => {
    albumTitleInput.current?.focus();
  }, [isNewAlbumTitle]);

  useEffect(() => {
    genreInput.current?.focus();
  }, [isNewGenre]);

  useEffect(() => {
    artistInput.current?.focus();
  }, [isNewArtist]);

  useEffect(() => {
    ISRCCodeInput.current?.focus();
  }, [isNewISRCCode]);

  useEffect(() => {
    UPCCodeInput.current?.focus();
  }, [isNewUPCCode]);

  useEffect(() => {
    composerInput.current?.focus();
  }, [isNewComposer]);

  useEffect(() => {
    publisherInput.current?.focus();
  }, [isNewPublisher]);

  const handleKeyDownAlbumTitleInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !!albumTitleInput.current?.value) {
      addAlbumTitle(currentVersion!, albumTitleInput.current?.value);
      enqueueSnackbar('Album title added successfully', { variant: 'success' });

      setIsNewAlbumTitle(false);
    }
  };

  const handleKeyDownGenreInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !!genreInput.current?.value) {
      addGenre(currentVersion!, genreInput.current?.value);
      enqueueSnackbar('Genre title added successfully', { variant: 'success' });

      setIsNewGenre(false);
    }
  };

  const handleKeyDownArtistInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !!artistInput.current?.value) {
      addArtist(currentVersion!, artistInput.current?.value);
      enqueueSnackbar('Artist title added successfully', { variant: 'success' });

      setIsNewArtist(false);
    }
  };

  const handleKeyDownISRCCodeInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !!ISRCCodeInput.current?.value) {
      addISRCCode(currentVersion!, ISRCCodeInput.current?.value);
      enqueueSnackbar('ISRC code added successfully', { variant: 'success' });

      setIsNewISRCCode(false);
    }
  };

  const handleKeyDownUPCCodeInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !!UPCCodeInput.current?.value) {
      addUPCCode(currentVersion!, UPCCodeInput.current?.value);
      enqueueSnackbar('UPC code added successfully', { variant: 'success' });

      setIsNewUPCCode(false);
    }
  };

  const handleKeyDownComposerInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      setIsNewComposer(false);
    }

    if (e.key === 'Enter' && !!composerInput.current?.value) {
      addComposer(currentVersion!, composerInput.current?.value, Number(composerOwnershipRateInput.current?.value ?? 0));
      enqueueSnackbar('Composer added successfully', { variant: 'success' });

      setIsNewComposer(false);
    }
  };

  const handleKeyDownPublisherInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      setIsNewComposer(false);
    }

    if (e.key === 'Enter' && !!publisherInput.current?.value) {
      addPublisher(currentVersion!, publisherInput.current?.value, Number(publisherOwnershipRateInput.current?.value ?? 0));
      enqueueSnackbar('Publisher added successfully', { variant: 'success' });

      setIsNewPublisher(false);
    }
  };

  const handleSampleRateChange = (event: SelectChangeEvent) => {
    changeSampleRate(+event.target.value);
    enqueueSnackbar('Sample rate changed successfully', { variant: 'success' });
  };

  const handleBitDepthChange = (event: SelectChangeEvent) => {
    changeBitDepth(+event.target.value);
    enqueueSnackbar('Bit depth changed successfully', { variant: 'success' });
  };

  return (
    <>
      {isMobile ? (
        <Drawer
          hideBackdrop
          anchor="bottom"
          open={isSidebarOpened}
          onClose={closeSidebar}
          PaperProps={{ sx: { backgroundColor: '#000000', backgroundImage: 'unset', borderTop: '1px solid #8B8B8B4D' } }}
        >
          <Stack
            height={`calc(100lvh - 150px`}
            sx={{ backgroundColor: '#151515', borderRadius: '8px', px: 2, pb: 2, py: 3.5 }}
            position="relative"
          >
            <IconButton
              size="small"
              onClick={closeSidebar}
              sx={{ maxHeight: '30px', minHeight: '30px', maxWidth: '30px', position: 'absolute', right: 25, top: 14 }}
            >
              <CloseIcon />
            </IconButton>
            <Typography px={3.25} pt={3.25} pb={0.5}>
              Metadata
            </Typography>
            <Box ref={containerRef} />
            {renderScroll && (
              <SimpleBar
                style={{
                  maxHeight: `calc(100lvh - 150px)`,
                  width: '100%'
                }}
              >
                <Grid container direction="column" rowGap={2.75} flexGrow={1} p={1.5}>
                  <MetadataBoxWrapper>
                    <SongBPM canEdit={canEdit} playlist={playlist} />
                  </MetadataBoxWrapper>
                  <MetadataBoxWrapper>
                    <SongKey canEdit={canEdit} />
                  </MetadataBoxWrapper>
                  <MetadataBoxWrapper>
                    <Typography mb={1.25}>Album title</Typography>
                    <Grid container gap={1.5}>
                      {metadata.albumTitles.map(album => (
                        <Grid
                          item
                          key={album.id}
                          py={0.5}
                          px={1.25}
                          borderRadius={2}
                          sx={{ backgroundColor: '#2B2B2B', maxHeight: '27px' }}
                        >
                          <Grid container columnSpacing={1.25} alignItems={'center'} sx={{ maxHeight: '17px' }}>
                            <Grid item>
                              <Typography fontSize={14} lineHeight={1}>
                                {album.albumTitle}
                              </Typography>
                            </Grid>
                            {canEdit && (
                              <Grid item>
                                <IconButton
                                  sx={{ maxHeight: '18px', maxWidth: '18px' }}
                                  onClick={() => {
                                    deleteAlbumTitle(album.id);
                                    enqueueSnackbar('Album title deleted successfully', { variant: 'success' });
                                  }}
                                >
                                  <CloseIcon sx={{ fontSize: '14px' }} />
                                </IconButton>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      ))}
                      {isNewAlbumTitle && (
                        <Grid item>
                          <TextField
                            size="small"
                            defaultValue={''}
                            inputRef={albumTitleInput}
                            onKeyDown={handleKeyDownAlbumTitleInput}
                            sx={{
                              width: 75,
                              fontSize: '12px',
                              maxHeight: '27px',
                              '& fieldset': { border: 'none', borderRadius: '4px' },
                              '& .MuiInputLabel-root': { fontSize: '12px' },
                              '& .MuiInputBase-root': { maxHeight: '27px' },
                              '& .MuiInputBase-input': { fontSize: '12px', px: '8px', fontWeight: 'bold', maxHeight: '27px' }
                            }}
                            onBlur={() => setIsNewAlbumTitle(false)}
                          />
                        </Grid>
                      )}
                      {canEdit && (
                        <Grid item>
                          <Button
                            onClick={() => setIsNewAlbumTitle(true)}
                            variant="contained"
                            size="small"
                            sx={{
                              height: '27px',
                              width: '27px',
                              minWidth: 'unset',
                              boxShadow: 'none',
                              backgroundColor: '#2B2B2B',
                              borderRadius: 2
                            }}
                          >
                            <AddIcon fontSize="small" sx={{ fontSize: '14px' }} />
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </MetadataBoxWrapper>
                  <MetadataBoxWrapper>
                    <Typography mb={1.25}>Genre(s)</Typography>
                    <Grid container gap={1.5}>
                      {metadata.genres.map(genre => (
                        <Grid
                          item
                          key={genre.id}
                          py={0.5}
                          px={1.25}
                          borderRadius={2}
                          sx={{ backgroundColor: '#2B2B2B', maxHeight: '27px' }}
                        >
                          <Grid container columnSpacing={1.25} alignItems={'center'} sx={{ maxHeight: '17px' }}>
                            <Grid item>
                              <Typography fontSize={14} lineHeight={1}>
                                {genre.genre}
                              </Typography>
                            </Grid>
                            {canEdit && (
                              <Grid item>
                                <IconButton
                                  sx={{ maxHeight: '18px', maxWidth: '18px' }}
                                  onClick={() => {
                                    deleteGenre(genre.id);
                                    enqueueSnackbar('Genre deleted successfully', { variant: 'success' });
                                  }}
                                >
                                  <CloseIcon sx={{ fontSize: '14px' }} />
                                </IconButton>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      ))}
                      {isNewGenre && (
                        <Grid item>
                          <TextField
                            size="small"
                            defaultValue={''}
                            inputRef={genreInput}
                            onKeyDown={handleKeyDownGenreInput}
                            sx={{
                              width: 75,
                              fontSize: '12px',
                              maxHeight: '27px',
                              '& fieldset': { border: 'none', borderRadius: '4px' },
                              '& .MuiInputLabel-root': { fontSize: '12px' },
                              '& .MuiInputBase-root': { maxHeight: '27px' },
                              '& .MuiInputBase-input': { fontSize: '12px', px: '8px', fontWeight: 'bold', maxHeight: '27px' }
                            }}
                            onBlur={() => setIsNewGenre(false)}
                          />
                        </Grid>
                      )}
                      {canEdit && (
                        <Grid item>
                          <Button
                            onClick={() => setIsNewGenre(true)}
                            variant="contained"
                            size="small"
                            sx={{
                              height: '27px',
                              width: '27px',
                              minWidth: 'unset',
                              boxShadow: 'none',
                              backgroundColor: '#2B2B2B',
                              borderRadius: 2
                            }}
                          >
                            <AddIcon fontSize="small" sx={{ fontSize: '14px' }} />
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </MetadataBoxWrapper>
                  <MetadataBoxWrapper>
                    <Typography mb={1.25}>Artist(s)</Typography>
                    <Grid container gap={1.5}>
                      {metadata.artists.map(artist => (
                        <Grid
                          item
                          key={artist.id}
                          py={0.5}
                          px={1.25}
                          borderRadius={2}
                          sx={{ backgroundColor: '#2B2B2B', maxHeight: '27px' }}
                        >
                          <Grid container columnSpacing={1.25} alignItems={'center'} sx={{ maxHeight: '17px' }}>
                            <Grid item>
                              <Typography fontSize={14} lineHeight={1}>
                                {artist.name}
                              </Typography>
                            </Grid>
                            {canEdit && (
                              <Grid item>
                                <IconButton
                                  sx={{ maxHeight: '18px', maxWidth: '18px' }}
                                  onClick={() => {
                                    deleteArtist(artist.id);
                                    enqueueSnackbar('Artist deleted successfully', { variant: 'success' });
                                  }}
                                >
                                  <CloseIcon sx={{ fontSize: '14px' }} />
                                </IconButton>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      ))}
                      {isNewArtist && (
                        <Grid item>
                          <TextField
                            size="small"
                            defaultValue={''}
                            inputRef={artistInput}
                            onKeyDown={handleKeyDownArtistInput}
                            sx={{
                              width: 75,
                              fontSize: '12px',
                              maxHeight: '27px',
                              '& fieldset': { border: 'none', borderRadius: '4px' },
                              '& .MuiInputLabel-root': { fontSize: '12px' },
                              '& .MuiInputBase-root': { maxHeight: '27px' },
                              '& .MuiInputBase-input': { fontSize: '12px', px: '8px', fontWeight: 'bold', maxHeight: '27px' }
                            }}
                            onBlur={() => setIsNewArtist(false)}
                          />
                        </Grid>
                      )}
                      {canEdit && (
                        <Grid item>
                          <Button
                            onClick={() => setIsNewArtist(true)}
                            variant="contained"
                            size="small"
                            sx={{
                              height: '27px',
                              width: '27px',
                              minWidth: 'unset',
                              boxShadow: 'none',
                              backgroundColor: '#2B2B2B',
                              borderRadius: 2
                            }}
                          >
                            <AddIcon fontSize="small" sx={{ fontSize: '14px' }} />
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </MetadataBoxWrapper>
                  <MetadataBoxWrapper>
                    <Typography mb={1.25}>Composer(s)</Typography>
                    <Grid container gap={1.5}>
                      {metadata.composers.map(composer => (
                        <Grid
                          item
                          key={composer.id}
                          width={1}
                          py={0.5}
                          px={1.25}
                          borderRadius={2}
                          sx={{ backgroundColor: '#2B2B2B', maxHeight: '27px' }}
                        >
                          <Grid container columnSpacing={1.25} alignItems={'center'} sx={{ maxHeight: '17px' }}>
                            <Grid item xs>
                              <Typography fontSize={14} lineHeight={1}>
                                {composer.name}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography fontSize={14} lineHeight={1}>
                                {composer.ownershipRate}%
                              </Typography>
                            </Grid>
                            {canEdit && (
                              <Grid item>
                                <IconButton
                                  sx={{ maxHeight: '18px', maxWidth: '18px' }}
                                  onClick={() => {
                                    deleteComposer(composer.id);
                                    enqueueSnackbar('Composer deleted successfully', { variant: 'success' });
                                  }}
                                >
                                  <CloseIcon sx={{ fontSize: '14px' }} />
                                </IconButton>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      ))}
                      {isNewComposer && (
                        <Grid item width={1} position={'relative'}>
                          <TextField
                            size="small"
                            defaultValue={''}
                            fullWidth
                            inputRef={composerInput}
                            onKeyDown={handleKeyDownComposerInput}
                            sx={{
                              fontSize: '12px',
                              maxHeight: '27px',
                              '& fieldset': { border: 'none', borderRadius: '4px' },
                              '& .MuiInputLabel-root': { fontSize: '12px' },
                              '& .MuiInputBase-root': { maxHeight: '27px' },
                              '& .MuiInputBase-input': {
                                fontSize: '12px',
                                pl: '8px',
                                pr: '70px',
                                fontWeight: 'bold',
                                maxHeight: '27px'
                              }
                            }}
                          />
                          <TextField
                            size="small"
                            defaultValue={''}
                            variant={'outlined'}
                            inputRef={composerOwnershipRateInput}
                            onKeyDown={handleKeyDownComposerInput}
                            sx={{
                              width: '60px',
                              top: 0,
                              right: 0,
                              position: 'absolute',
                              fontSize: '12px',
                              maxHeight: '27px',
                              '& fieldset': { border: 'none', borderRadius: '4px' },
                              '& .MuiInputLabel-root': { fontSize: '12px' },
                              '& .MuiInputBase-root': { maxHeight: '27px', backgroundColor: 'transparent' },
                              '& .MuiInputBase-input': {
                                fontSize: '12px',
                                px: '0',
                                pr: '35px',
                                fontWeight: 'bold',
                                maxHeight: '27px'
                              }
                            }}
                          />
                          <Typography
                            fontSize={14}
                            lineHeight={1}
                            sx={{ top: '50%', right: 25, position: 'absolute', transform: 'translateY(-50%)' }}
                          >
                            %
                          </Typography>
                        </Grid>
                      )}
                      {canEdit && (
                        <Grid item>
                          <Button
                            onClick={() => setIsNewComposer(true)}
                            variant="contained"
                            size="small"
                            sx={{
                              height: '27px',
                              width: '27px',
                              minWidth: 'unset',
                              boxShadow: 'none',
                              backgroundColor: '#2B2B2B',
                              borderRadius: 2
                            }}
                          >
                            <AddIcon fontSize="small" sx={{ fontSize: '14px' }} />
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </MetadataBoxWrapper>
                  <MetadataBoxWrapper>
                    <Typography mb={1.25}>Publisher(s)</Typography>
                    <Grid container gap={1.5}>
                      {metadata.publishers.map(publisher => (
                        <Grid
                          item
                          key={publisher.id}
                          width={1}
                          py={0.5}
                          px={1.25}
                          borderRadius={2}
                          sx={{ backgroundColor: '#2B2B2B', maxHeight: '27px' }}
                        >
                          <Grid container columnSpacing={1.25} alignItems={'center'} sx={{ maxHeight: '17px' }}>
                            <Grid item xs>
                              <Typography fontSize={14} lineHeight={1}>
                                {publisher.name}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography fontSize={14} lineHeight={1}>
                                {publisher.ownershipRate}%
                              </Typography>
                            </Grid>
                            {canEdit && (
                              <Grid item>
                                <IconButton
                                  sx={{ maxHeight: '18px', maxWidth: '18px' }}
                                  onClick={() => {
                                    deletePublisher(publisher.id);
                                    enqueueSnackbar('Publisher deleted successfully', { variant: 'success' });
                                  }}
                                >
                                  <CloseIcon sx={{ fontSize: '14px' }} />
                                </IconButton>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      ))}
                      {isNewPublisher && (
                        <Grid item width={1} position={'relative'}>
                          <TextField
                            size="small"
                            defaultValue={''}
                            fullWidth
                            inputRef={publisherInput}
                            onKeyDown={handleKeyDownPublisherInput}
                            sx={{
                              fontSize: '12px',
                              maxHeight: '27px',
                              '& fieldset': { border: 'none', borderRadius: '4px' },
                              '& .MuiInputLabel-root': { fontSize: '12px' },
                              '& .MuiInputBase-root': { maxHeight: '27px' },
                              '& .MuiInputBase-input': {
                                fontSize: '12px',
                                pl: '8px',
                                pr: '70px',
                                fontWeight: 'bold',
                                maxHeight: '27px'
                              }
                            }}
                          />
                          <TextField
                            size="small"
                            defaultValue={''}
                            variant={'outlined'}
                            inputRef={publisherOwnershipRateInput}
                            onKeyDown={handleKeyDownPublisherInput}
                            sx={{
                              width: '60px',
                              top: 0,
                              right: 0,
                              position: 'absolute',
                              fontSize: '12px',
                              maxHeight: '27px',
                              '& fieldset': { border: 'none', borderRadius: '4px' },
                              '& .MuiInputLabel-root': { fontSize: '12px' },
                              '& .MuiInputBase-root': { maxHeight: '27px', backgroundColor: 'transparent' },
                              '& .MuiInputBase-input': {
                                fontSize: '12px',
                                px: '0',
                                pr: '35px',
                                fontWeight: 'bold',
                                maxHeight: '27px'
                              }
                            }}
                          />
                          <Typography
                            fontSize={14}
                            lineHeight={1}
                            sx={{ top: '50%', right: 25, position: 'absolute', transform: 'translateY(-50%)' }}
                          >
                            %
                          </Typography>
                        </Grid>
                      )}
                      {canEdit && (
                        <Grid item>
                          <Button
                            onClick={() => setIsNewPublisher(true)}
                            variant="contained"
                            size="small"
                            sx={{
                              height: '27px',
                              width: '27px',
                              minWidth: 'unset',
                              boxShadow: 'none',
                              backgroundColor: '#2B2B2B',
                              borderRadius: 2
                            }}
                          >
                            <AddIcon fontSize="small" sx={{ fontSize: '14px' }} />
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </MetadataBoxWrapper>
                  <MetadataBoxWrapper>
                    <Typography mb={1.25}>ISRC Code</Typography>
                    <Grid container gap={1.5}>
                      {metadata.ISRCCodes.map(ISRCCode => (
                        <Grid
                          item
                          key={ISRCCode.id}
                          py={0.5}
                          px={1.25}
                          borderRadius={2}
                          sx={{ backgroundColor: '#2B2B2B', maxHeight: '27px' }}
                        >
                          <Grid container columnSpacing={1.25} alignItems={'center'} sx={{ maxHeight: '17px' }}>
                            <Grid item>
                              <Typography fontSize={14} lineHeight={1}>
                                {ISRCCode.code}
                              </Typography>
                            </Grid>
                            {canEdit && (
                              <Grid item>
                                <IconButton
                                  sx={{ maxHeight: '18px', maxWidth: '18px' }}
                                  onClick={() => {
                                    deleteISRCCode(ISRCCode.id);
                                    enqueueSnackbar('ISRC code deleted successfully', { variant: 'success' });
                                  }}
                                >
                                  <CloseIcon sx={{ fontSize: '14px' }} />
                                </IconButton>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      ))}
                      {isNewISRCCode && (
                        <Grid item>
                          <TextField
                            size="small"
                            defaultValue={''}
                            inputRef={ISRCCodeInput}
                            onKeyDown={handleKeyDownISRCCodeInput}
                            sx={{
                              width: 75,
                              fontSize: '12px',
                              maxHeight: '27px',
                              '& fieldset': { border: 'none', borderRadius: '4px' },
                              '& .MuiInputLabel-root': { fontSize: '12px' },
                              '& .MuiInputBase-root': { maxHeight: '27px' },
                              '& .MuiInputBase-input': { fontSize: '12px', px: '8px', fontWeight: 'bold', maxHeight: '27px' }
                            }}
                            onBlur={() => setIsNewISRCCode(false)}
                          />
                        </Grid>
                      )}
                      <Grid item>
                        {canEdit && (
                          <Button
                            onClick={() => setIsNewISRCCode(true)}
                            variant="contained"
                            size="small"
                            sx={{
                              height: '27px',
                              width: '27px',
                              minWidth: 'unset',
                              boxShadow: 'none',
                              backgroundColor: '#2B2B2B',
                              borderRadius: 2
                            }}
                          >
                            <AddIcon fontSize="small" sx={{ fontSize: '14px' }} />
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </MetadataBoxWrapper>
                  <MetadataBoxWrapper>
                    <Typography mb={1.25}>UPC Code</Typography>
                    <Grid container gap={1.5}>
                      {metadata.UPCCodes.map(UPCCode => (
                        <Grid
                          item
                          key={UPCCode.id}
                          py={0.5}
                          px={1.25}
                          borderRadius={2}
                          sx={{ backgroundColor: '#2B2B2B', maxHeight: '27px' }}
                        >
                          <Grid container columnSpacing={1.25} alignItems={'center'} sx={{ maxHeight: '17px' }}>
                            <Grid item>
                              <Typography fontSize={14} lineHeight={1}>
                                {UPCCode.code}
                              </Typography>
                            </Grid>
                            {canEdit && (
                              <Grid item>
                                <IconButton
                                  sx={{ maxHeight: '18px', maxWidth: '18px' }}
                                  onClick={() => {
                                    deleteUPCCode(UPCCode.id);
                                    enqueueSnackbar('UPC code deleted successfully', { variant: 'success' });
                                  }}
                                >
                                  <CloseIcon sx={{ fontSize: '14px' }} />
                                </IconButton>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      ))}
                      {isNewUPCCode && (
                        <Grid item>
                          <TextField
                            size="small"
                            defaultValue={''}
                            inputRef={UPCCodeInput}
                            onKeyDown={handleKeyDownUPCCodeInput}
                            sx={{
                              width: 75,
                              fontSize: '12px',
                              maxHeight: '27px',
                              '& fieldset': { border: 'none', borderRadius: '4px' },
                              '& .MuiInputLabel-root': { fontSize: '12px' },
                              '& .MuiInputBase-root': { maxHeight: '27px' },
                              '& .MuiInputBase-input': { fontSize: '12px', px: '8px', fontWeight: 'bold', maxHeight: '27px' }
                            }}
                            onBlur={() => setIsNewUPCCode(false)}
                          />
                        </Grid>
                      )}
                      {canEdit && (
                        <Grid item>
                          <Button
                            onClick={() => setIsNewUPCCode(true)}
                            variant="contained"
                            size="small"
                            sx={{
                              height: '27px',
                              width: '27px',
                              minWidth: 'unset',
                              boxShadow: 'none',
                              backgroundColor: '#2B2B2B',
                              borderRadius: 2
                            }}
                          >
                            <AddIcon fontSize="small" sx={{ fontSize: '14px' }} />
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </MetadataBoxWrapper>

                  <MetadataBoxWrapper>
                    <Grid container direction="row" columnSpacing={1.5}>
                      <Grid item xs={'auto'} direction={'column'}>
                        <Stack direction={'column'}>
                          <Typography sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '17px' }}>Sample Rate</Typography>
                          <Select
                            disabled={!canEdit}
                            IconComponent={ArrowDown}
                            defaultValue={metadata?.sampleRate?.toString() ?? MetadataSampleRateValues.FortyFourPointOne.toString()}
                            onChange={handleSampleRateChange}
                            sx={{
                              color: '#ABABAB',
                              backgroundColor: '#2B2B2B',
                              boxShadow: 'none',
                              borderRadius: '8px 8px 0px 0px',
                              borderBottom: '0.6px solid #737373',
                              marginTop: '12px',
                              '.MuiOutlinedInput-notchedOutline': { border: 0 },
                              '&.MuiSelect-select': {
                                paddingY: '0px !important'
                              },
                              '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                border: 0
                              },
                              '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                border: 0
                              },
                              '& .MuiSelect-select': {
                                py: '0 !important'
                              }
                            }}
                          >
                            {Object.entries(MetadataSampleRateValues)
                              .filter(([key, sampleRate]) => typeof sampleRate !== 'string')
                              .map(([key, sampleRate]) => (
                                <MenuItem key={sampleRate} value={sampleRate}>
                                  {sampleRate} KHz
                                </MenuItem>
                              ))}
                          </Select>
                        </Stack>
                      </Grid>
                      <Grid item xs={'auto'}>
                        <Stack direction={'column'}>
                          <Typography sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '17px' }}> Bit Depth</Typography>
                          <Select
                            defaultValue={metadata?.bitDepth?.toString() ?? MetadataBitDepthValues.Sixteen.toString()}
                            onChange={handleBitDepthChange}
                            IconComponent={ArrowDown}
                            disabled={!canEdit}
                            sx={{
                              color: '#ABABAB',
                              backgroundColor: '#2B2B2B',
                              borderRadius: '8px 8px 0px 0px',
                              borderBottom: '0.6px solid #737373',
                              boxShadow: 'none',
                              marginTop: '12px',
                              '.MuiOutlinedInput-notchedOutline': { border: 0 },
                              '&.MuiSelect-select': {
                                paddingY: '0px !important'
                              },
                              '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                border: 0
                              },
                              '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                border: 0
                              },
                              '& .MuiSelect-select': {
                                py: '0 !important'
                              }
                            }}
                          >
                            {Object.entries(MetadataBitDepthValues)
                              .filter(([key, bitDepth]) => typeof bitDepth !== 'string')
                              .map(([key, bitDepth]) => (
                                <MenuItem key={bitDepth} value={bitDepth}>
                                  {bitDepth} bit
                                </MenuItem>
                              ))}
                          </Select>
                        </Stack>
                      </Grid>
                    </Grid>
                  </MetadataBoxWrapper>

                  <MetadataBoxWrapper>
                    <Typography mb={1.25}>Lyrics</Typography>
                    <Divider sx={{ mb: 1.25 }} />
                    <TextField
                      disabled={!canEdit}
                      size="small"
                      value={lyrics}
                      multiline
                      rows={3}
                      onChange={e => {
                        setLyrics(e.target.value);
                      }}
                      fullWidth
                      placeholder={'Amazing lyrics should be written here.'}
                      sx={{
                        fontSize: '12px',
                        '& fieldset': { border: 'none', borderRadius: '4px' },
                        '& .MuiInputLabel-root': { fontSize: '12px' },
                        '& .MuiInputBase-root': { backgroundColor: 'transparent', p: 0 },
                        '& .MuiInputBase-input': { fontSize: '12px', p: 0, fontWeight: 'bold' }
                      }}
                      onBlur={() => {
                        if (oldLyrics !== lyrics) {
                          setOldLyrics(lyrics);
                          addEditLyrics(currentVersion!, String(lyrics));
                          enqueueSnackbar('Lyrics edited successfully', { variant: 'success' });
                        }
                      }}
                      InputProps={{}}
                    />
                  </MetadataBoxWrapper>
                </Grid>
              </SimpleBar>
            )}
          </Stack>
        </Drawer>
      ) : (
        <>
          <Box ref={metadataContainerRef} />
          <Stack height={1} sx={{ backgroundColor: '#151515', borderRadius: '8px', px: 2, pb: 2, py: 3.5 }} position="relative">
            <IconButton
              size="small"
              onClick={closeSidebar}
              sx={{ maxHeight: '30px', minHeight: '30px', maxWidth: '30px', position: 'absolute', right: 14, top: 14 }}
            >
              <CloseIcon />
            </IconButton>
            <Typography ml={1.25} mb={2.75}>
              Metadata
            </Typography>
            <Box ref={containerRef} />
            {renderScroll && (
              <SimpleBar
                style={{
                  maxHeight: `calc(100svh - 52px - ${containerRef.current?.offsetTop ?? 0}px - ${
                    metadataContainerRef.current?.offsetTop ?? 0
                  }px)`,
                  width: '100%'
                }}
              >
                <Grid container direction="column" rowGap={2.75} flexGrow={1}>
                  <MetadataBoxWrapper>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={async () => {
                        try {
                          setIsSavingMetadata(true);
                          await songMetadataService.saveMetadata(currentVersion!);
                          setIsSavingMetadata(false);
                          enqueueSnackbar('Saved Metadata successfully', { variant: 'success' });
                        } catch (error) {
                          setIsSavingMetadata(false);
                          enqueueSnackbar('Failed to save metadata', { variant: 'error' });
                        }
                      }}
                      disabled={!canEdit || isSavingMetadata}
                    >
                      save
                    </Button>
                  </MetadataBoxWrapper>
                  <MetadataBoxWrapper>
                    <SongBPM canEdit={canEdit} playlist={playlist} />
                  </MetadataBoxWrapper>
                  <MetadataBoxWrapper>
                    <SongKey canEdit={canEdit} />
                  </MetadataBoxWrapper>
                  <MetadataBoxWrapper>
                    <Typography mb={1.25}>Album title</Typography>
                    <Grid container gap={1.5}>
                      {metadata.albumTitles.map(album => (
                        <Grid
                          item
                          key={album.id}
                          py={0.5}
                          px={1.25}
                          borderRadius={2}
                          sx={{ backgroundColor: '#2B2B2B', maxHeight: '27px' }}
                        >
                          <Grid container columnSpacing={1.25} alignItems={'center'} sx={{ maxHeight: '17px' }}>
                            <Grid item>
                              <Typography fontSize={14} lineHeight={1}>
                                {album.albumTitle}
                              </Typography>
                            </Grid>
                            {canEdit && (
                              <Grid item>
                                <IconButton
                                  sx={{ maxHeight: '18px', maxWidth: '18px' }}
                                  onClick={() => {
                                    deleteAlbumTitle(album.id);
                                    enqueueSnackbar('Album title deleted successfully', { variant: 'success' });
                                  }}
                                >
                                  <CloseIcon sx={{ fontSize: '14px' }} />
                                </IconButton>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      ))}
                      {isNewAlbumTitle && (
                        <Grid item>
                          <TextField
                            size="small"
                            defaultValue={''}
                            inputRef={albumTitleInput}
                            onKeyDown={handleKeyDownAlbumTitleInput}
                            sx={{
                              width: 75,
                              fontSize: '12px',
                              maxHeight: '27px',
                              '& fieldset': { border: 'none', borderRadius: '4px' },
                              '& .MuiInputLabel-root': { fontSize: '12px' },
                              '& .MuiInputBase-root': { maxHeight: '27px' },
                              '& .MuiInputBase-input': { fontSize: '12px', px: '8px', fontWeight: 'bold', maxHeight: '27px' }
                            }}
                            onBlur={() => setIsNewAlbumTitle(false)}
                          />
                        </Grid>
                      )}
                      {canEdit && (
                        <Grid item>
                          <Button
                            onClick={() => setIsNewAlbumTitle(true)}
                            variant="contained"
                            size="small"
                            sx={{
                              height: '27px',
                              width: '27px',
                              minWidth: 'unset',
                              boxShadow: 'none',
                              backgroundColor: '#2B2B2B',
                              borderRadius: 2
                            }}
                          >
                            <AddIcon fontSize="small" sx={{ fontSize: '14px' }} />
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </MetadataBoxWrapper>
                  <MetadataBoxWrapper>
                    <Typography mb={1.25}>Genre(s)</Typography>
                    <Grid container gap={1.5}>
                      {metadata.genres.map(genre => (
                        <Grid
                          item
                          key={genre.id}
                          py={0.5}
                          px={1.25}
                          borderRadius={2}
                          sx={{ backgroundColor: '#2B2B2B', maxHeight: '27px' }}
                        >
                          <Grid container columnSpacing={1.25} alignItems={'center'} sx={{ maxHeight: '17px' }}>
                            <Grid item>
                              <Typography fontSize={14} lineHeight={1}>
                                {genre.genre}
                              </Typography>
                            </Grid>
                            {canEdit && (
                              <Grid item>
                                <IconButton
                                  sx={{ maxHeight: '18px', maxWidth: '18px' }}
                                  onClick={() => {
                                    deleteGenre(genre.id);
                                    enqueueSnackbar('Genre deleted successfully', { variant: 'success' });
                                  }}
                                >
                                  <CloseIcon sx={{ fontSize: '14px' }} />
                                </IconButton>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      ))}
                      {isNewGenre && (
                        <Grid item>
                          <TextField
                            size="small"
                            defaultValue={''}
                            inputRef={genreInput}
                            onKeyDown={handleKeyDownGenreInput}
                            sx={{
                              width: 75,
                              fontSize: '12px',
                              maxHeight: '27px',
                              '& fieldset': { border: 'none', borderRadius: '4px' },
                              '& .MuiInputLabel-root': { fontSize: '12px' },
                              '& .MuiInputBase-root': { maxHeight: '27px' },
                              '& .MuiInputBase-input': { fontSize: '12px', px: '8px', fontWeight: 'bold', maxHeight: '27px' }
                            }}
                            onBlur={() => setIsNewGenre(false)}
                          />
                        </Grid>
                      )}
                      {canEdit && (
                        <Grid item>
                          <Button
                            onClick={() => setIsNewGenre(true)}
                            variant="contained"
                            size="small"
                            sx={{
                              height: '27px',
                              width: '27px',
                              minWidth: 'unset',
                              boxShadow: 'none',
                              backgroundColor: '#2B2B2B',
                              borderRadius: 2
                            }}
                          >
                            <AddIcon fontSize="small" sx={{ fontSize: '14px' }} />
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </MetadataBoxWrapper>
                  <MetadataBoxWrapper>
                    <Typography mb={1.25}>Artist(s)</Typography>
                    <Grid container gap={1.5}>
                      {metadata.artists.map(artist => (
                        <Grid
                          item
                          key={artist.id}
                          py={0.5}
                          px={1.25}
                          borderRadius={2}
                          sx={{ backgroundColor: '#2B2B2B', maxHeight: '27px' }}
                        >
                          <Grid container columnSpacing={1.25} alignItems={'center'} sx={{ maxHeight: '17px' }}>
                            <Grid item>
                              <Typography fontSize={14} lineHeight={1}>
                                {artist.name}
                              </Typography>
                            </Grid>
                            {canEdit && (
                              <Grid item>
                                <IconButton
                                  sx={{ maxHeight: '18px', maxWidth: '18px' }}
                                  onClick={() => {
                                    deleteArtist(artist.id);
                                    enqueueSnackbar('Artist deleted successfully', { variant: 'success' });
                                  }}
                                >
                                  <CloseIcon sx={{ fontSize: '14px' }} />
                                </IconButton>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      ))}
                      {isNewArtist && (
                        <Grid item>
                          <TextField
                            size="small"
                            defaultValue={''}
                            inputRef={artistInput}
                            onKeyDown={handleKeyDownArtistInput}
                            sx={{
                              width: 75,
                              fontSize: '12px',
                              maxHeight: '27px',
                              '& fieldset': { border: 'none', borderRadius: '4px' },
                              '& .MuiInputLabel-root': { fontSize: '12px' },
                              '& .MuiInputBase-root': { maxHeight: '27px' },
                              '& .MuiInputBase-input': { fontSize: '12px', px: '8px', fontWeight: 'bold', maxHeight: '27px' }
                            }}
                            onBlur={() => setIsNewArtist(false)}
                          />
                        </Grid>
                      )}
                      {canEdit && (
                        <Grid item>
                          <Button
                            onClick={() => setIsNewArtist(true)}
                            variant="contained"
                            size="small"
                            sx={{
                              height: '27px',
                              width: '27px',
                              minWidth: 'unset',
                              boxShadow: 'none',
                              backgroundColor: '#2B2B2B',
                              borderRadius: 2
                            }}
                          >
                            <AddIcon fontSize="small" sx={{ fontSize: '14px' }} />
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </MetadataBoxWrapper>
                  <MetadataBoxWrapper>
                    <Typography mb={1.25}>Composer(s)</Typography>
                    <Grid container gap={1.5}>
                      {metadata.composers.map(composer => (
                        <Grid
                          item
                          key={composer.id}
                          width={1}
                          py={0.5}
                          px={1.25}
                          borderRadius={2}
                          sx={{ backgroundColor: '#2B2B2B', maxHeight: '27px' }}
                        >
                          <Grid container columnSpacing={1.25} alignItems={'center'} sx={{ maxHeight: '17px' }}>
                            <Grid item xs>
                              <Typography fontSize={14} lineHeight={1}>
                                {composer.name}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography fontSize={14} lineHeight={1}>
                                {composer.ownershipRate}%
                              </Typography>
                            </Grid>
                            {canEdit && (
                              <Grid item>
                                <IconButton
                                  sx={{ maxHeight: '18px', maxWidth: '18px' }}
                                  onClick={() => {
                                    deleteComposer(composer.id);
                                    enqueueSnackbar('Composer deleted successfully', { variant: 'success' });
                                  }}
                                >
                                  <CloseIcon sx={{ fontSize: '14px' }} />
                                </IconButton>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      ))}
                      {isNewComposer && (
                        <Grid item width={1} position={'relative'}>
                          <TextField
                            size="small"
                            defaultValue={''}
                            fullWidth
                            inputRef={composerInput}
                            onKeyDown={handleKeyDownComposerInput}
                            sx={{
                              fontSize: '12px',
                              maxHeight: '27px',
                              '& fieldset': { border: 'none', borderRadius: '4px' },
                              '& .MuiInputLabel-root': { fontSize: '12px' },
                              '& .MuiInputBase-root': { maxHeight: '27px' },
                              '& .MuiInputBase-input': {
                                fontSize: '12px',
                                pl: '8px',
                                pr: '70px',
                                fontWeight: 'bold',
                                maxHeight: '27px'
                              }
                            }}
                          />
                          <TextField
                            size="small"
                            defaultValue={''}
                            variant={'outlined'}
                            inputRef={composerOwnershipRateInput}
                            onKeyDown={handleKeyDownComposerInput}
                            sx={{
                              width: '60px',
                              top: 0,
                              right: 0,
                              position: 'absolute',
                              fontSize: '12px',
                              maxHeight: '27px',
                              '& fieldset': { border: 'none', borderRadius: '4px' },
                              '& .MuiInputLabel-root': { fontSize: '12px' },
                              '& .MuiInputBase-root': { maxHeight: '27px', backgroundColor: 'transparent' },
                              '& .MuiInputBase-input': {
                                fontSize: '12px',
                                px: '0',
                                pr: '35px',
                                fontWeight: 'bold',
                                maxHeight: '27px'
                              }
                            }}
                          />
                          <Typography
                            fontSize={14}
                            lineHeight={1}
                            sx={{ top: '50%', right: 25, position: 'absolute', transform: 'translateY(-50%)' }}
                          >
                            %
                          </Typography>
                        </Grid>
                      )}
                      {canEdit && (
                        <Grid item>
                          <Button
                            onClick={() => setIsNewComposer(true)}
                            variant="contained"
                            size="small"
                            sx={{
                              height: '27px',
                              width: '27px',
                              minWidth: 'unset',
                              boxShadow: 'none',
                              backgroundColor: '#2B2B2B',
                              borderRadius: 2
                            }}
                          >
                            <AddIcon fontSize="small" sx={{ fontSize: '14px' }} />
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </MetadataBoxWrapper>
                  <MetadataBoxWrapper>
                    <Typography mb={1.25}>Publisher(s)</Typography>
                    <Grid container gap={1.5}>
                      {metadata.publishers.map(publisher => (
                        <Grid
                          item
                          key={publisher.id}
                          width={1}
                          py={0.5}
                          px={1.25}
                          borderRadius={2}
                          sx={{ backgroundColor: '#2B2B2B', maxHeight: '27px' }}
                        >
                          <Grid container columnSpacing={1.25} alignItems={'center'} sx={{ maxHeight: '17px' }}>
                            <Grid item xs>
                              <Typography fontSize={14} lineHeight={1}>
                                {publisher.name}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography fontSize={14} lineHeight={1}>
                                {publisher.ownershipRate}%
                              </Typography>
                            </Grid>
                            {canEdit && (
                              <Grid item>
                                <IconButton
                                  sx={{ maxHeight: '18px', maxWidth: '18px' }}
                                  onClick={() => {
                                    deletePublisher(publisher.id);
                                    enqueueSnackbar('Composer Publisher successfully', { variant: 'success' });
                                  }}
                                >
                                  <CloseIcon sx={{ fontSize: '14px' }} />
                                </IconButton>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      ))}
                      {isNewPublisher && (
                        <Grid item width={1} position={'relative'}>
                          <TextField
                            size="small"
                            defaultValue={''}
                            fullWidth
                            inputRef={publisherInput}
                            onKeyDown={handleKeyDownPublisherInput}
                            sx={{
                              fontSize: '12px',
                              maxHeight: '27px',
                              '& fieldset': { border: 'none', borderRadius: '4px' },
                              '& .MuiInputLabel-root': { fontSize: '12px' },
                              '& .MuiInputBase-root': { maxHeight: '27px' },
                              '& .MuiInputBase-input': {
                                fontSize: '12px',
                                pl: '8px',
                                pr: '70px',
                                fontWeight: 'bold',
                                maxHeight: '27px'
                              }
                            }}
                          />
                          <TextField
                            size="small"
                            defaultValue={''}
                            variant={'outlined'}
                            inputRef={publisherOwnershipRateInput}
                            onKeyDown={handleKeyDownPublisherInput}
                            sx={{
                              width: '60px',
                              top: 0,
                              right: 0,
                              position: 'absolute',
                              fontSize: '12px',
                              maxHeight: '27px',
                              '& fieldset': { border: 'none', borderRadius: '4px' },
                              '& .MuiInputLabel-root': { fontSize: '12px' },
                              '& .MuiInputBase-root': { maxHeight: '27px', backgroundColor: 'transparent' },
                              '& .MuiInputBase-input': {
                                fontSize: '12px',
                                px: '0',
                                pr: '35px',
                                fontWeight: 'bold',
                                maxHeight: '27px'
                              }
                            }}
                          />
                          <Typography
                            fontSize={14}
                            lineHeight={1}
                            sx={{ top: '50%', right: 25, position: 'absolute', transform: 'translateY(-50%)' }}
                          >
                            %
                          </Typography>
                        </Grid>
                      )}
                      {canEdit && (
                        <Grid item>
                          <Button
                            onClick={() => setIsNewPublisher(true)}
                            variant="contained"
                            size="small"
                            sx={{
                              height: '27px',
                              width: '27px',
                              minWidth: 'unset',
                              boxShadow: 'none',
                              backgroundColor: '#2B2B2B',
                              borderRadius: 2
                            }}
                          >
                            <AddIcon fontSize="small" sx={{ fontSize: '14px' }} />
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </MetadataBoxWrapper>
                  <MetadataBoxWrapper>
                    <Typography mb={1.25}>ISRC Code</Typography>
                    <Grid container gap={1.5}>
                      {metadata.ISRCCodes.map(ISRCCode => (
                        <Grid
                          item
                          key={ISRCCode.id}
                          py={0.5}
                          px={1.25}
                          borderRadius={2}
                          sx={{ backgroundColor: '#2B2B2B', maxHeight: '27px' }}
                        >
                          <Grid container columnSpacing={1.25} alignItems={'center'} sx={{ maxHeight: '17px' }}>
                            <Grid item>
                              <Typography fontSize={14} lineHeight={1}>
                                {ISRCCode.code}
                              </Typography>
                            </Grid>
                            {canEdit && (
                              <Grid item>
                                <IconButton
                                  sx={{ maxHeight: '18px', maxWidth: '18px' }}
                                  onClick={() => {
                                    deleteISRCCode(ISRCCode.id);
                                    enqueueSnackbar('ISRC code deleted successfully', { variant: 'success' });
                                  }}
                                >
                                  <CloseIcon sx={{ fontSize: '14px' }} />
                                </IconButton>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      ))}
                      {isNewISRCCode && (
                        <Grid item>
                          <TextField
                            size="small"
                            defaultValue={''}
                            inputRef={ISRCCodeInput}
                            onKeyDown={handleKeyDownISRCCodeInput}
                            sx={{
                              width: 75,
                              fontSize: '12px',
                              maxHeight: '27px',
                              '& fieldset': { border: 'none', borderRadius: '4px' },
                              '& .MuiInputLabel-root': { fontSize: '12px' },
                              '& .MuiInputBase-root': { maxHeight: '27px' },
                              '& .MuiInputBase-input': { fontSize: '12px', px: '8px', fontWeight: 'bold', maxHeight: '27px' }
                            }}
                            onBlur={() => setIsNewISRCCode(false)}
                          />
                        </Grid>
                      )}
                      <Grid item>
                        {canEdit && (
                          <Button
                            onClick={() => setIsNewISRCCode(true)}
                            variant="contained"
                            size="small"
                            sx={{
                              height: '27px',
                              width: '27px',
                              minWidth: 'unset',
                              boxShadow: 'none',
                              backgroundColor: '#2B2B2B',
                              borderRadius: 2
                            }}
                          >
                            <AddIcon fontSize="small" sx={{ fontSize: '14px' }} />
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </MetadataBoxWrapper>
                  <MetadataBoxWrapper>
                    <Typography mb={1.25}>UPC Code</Typography>
                    <Grid container gap={1.5}>
                      {metadata.UPCCodes.map(UPCCode => (
                        <Grid
                          item
                          key={UPCCode.id}
                          py={0.5}
                          px={1.25}
                          borderRadius={2}
                          sx={{ backgroundColor: '#2B2B2B', maxHeight: '27px' }}
                        >
                          <Grid container columnSpacing={1.25} alignItems={'center'} sx={{ maxHeight: '17px' }}>
                            <Grid item>
                              <Typography fontSize={14} lineHeight={1}>
                                {UPCCode.code}
                              </Typography>
                            </Grid>
                            {canEdit && (
                              <Grid item>
                                <IconButton
                                  sx={{ maxHeight: '18px', maxWidth: '18px' }}
                                  onClick={() => {
                                    deleteUPCCode(UPCCode.id);
                                    enqueueSnackbar('UPC code deleted successfully', { variant: 'success' });
                                  }}
                                >
                                  <CloseIcon sx={{ fontSize: '14px' }} />
                                </IconButton>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      ))}
                      {isNewUPCCode && (
                        <Grid item>
                          <TextField
                            size="small"
                            defaultValue={''}
                            inputRef={UPCCodeInput}
                            onKeyDown={handleKeyDownUPCCodeInput}
                            sx={{
                              width: 75,
                              fontSize: '12px',
                              maxHeight: '27px',
                              '& fieldset': { border: 'none', borderRadius: '4px' },
                              '& .MuiInputLabel-root': { fontSize: '12px' },
                              '& .MuiInputBase-root': { maxHeight: '27px' },
                              '& .MuiInputBase-input': { fontSize: '12px', px: '8px', fontWeight: 'bold', maxHeight: '27px' }
                            }}
                            onBlur={() => setIsNewUPCCode(false)}
                          />
                        </Grid>
                      )}
                      {canEdit && (
                        <Grid item>
                          <Button
                            onClick={() => setIsNewUPCCode(true)}
                            variant="contained"
                            size="small"
                            sx={{
                              height: '27px',
                              width: '27px',
                              minWidth: 'unset',
                              boxShadow: 'none',
                              backgroundColor: '#2B2B2B',
                              borderRadius: 2
                            }}
                          >
                            <AddIcon fontSize="small" sx={{ fontSize: '14px' }} />
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </MetadataBoxWrapper>

                  <MetadataBoxWrapper>
                    <Grid container direction="row" columnSpacing={1.5}>
                      <Grid item xs={'auto'} direction={'column'}>
                        <Stack direction={'column'}>
                          <Typography sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '17px' }}>Sample Rate</Typography>
                          <Select
                            IconComponent={ArrowDown}
                            defaultValue={metadata?.sampleRate?.toString() ?? MetadataSampleRateValues.FortyFourPointOne.toString()}
                            onChange={handleSampleRateChange}
                            disabled={!canEdit}
                            sx={{
                              color: '#ABABAB',
                              backgroundColor: '#2B2B2B',
                              boxShadow: 'none',
                              borderRadius: '8px 8px 0px 0px',
                              borderBottom: '0.6px solid #737373',
                              marginTop: '12px',
                              '.MuiOutlinedInput-notchedOutline': { border: 0 },
                              '&.MuiSelect-select': {
                                paddingY: '0px !important'
                              },
                              '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                border: 0
                              },
                              '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                border: 0
                              },
                              '& .MuiSelect-select': {
                                py: '0 !important'
                              }
                            }}
                          >
                            {Object.entries(MetadataSampleRateValues)
                              .filter(([key, sampleRate]) => typeof sampleRate !== 'string')
                              .map(([key, sampleRate]) => (
                                <MenuItem key={sampleRate} value={sampleRate}>
                                  {sampleRate} KHz
                                </MenuItem>
                              ))}
                          </Select>
                        </Stack>
                      </Grid>
                      <Grid item xs={'auto'}>
                        <Stack direction={'column'}>
                          <Typography sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '17px' }}> Bit Depth</Typography>
                          <Select
                            defaultValue={metadata?.bitDepth?.toString() ?? MetadataBitDepthValues.Sixteen.toString()}
                            onChange={handleBitDepthChange}
                            IconComponent={ArrowDown}
                            disabled={!canEdit}
                            sx={{
                              color: '#ABABAB',
                              backgroundColor: '#2B2B2B',
                              borderRadius: '8px 8px 0px 0px',
                              borderBottom: '0.6px solid #737373',
                              boxShadow: 'none',
                              marginTop: '12px',
                              '.MuiOutlinedInput-notchedOutline': { border: 0 },
                              '&.MuiSelect-select': {
                                paddingY: '0px !important'
                              },
                              '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                border: 0
                              },
                              '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                border: 0
                              },
                              '& .MuiSelect-select': {
                                py: '0 !important'
                              }
                            }}
                          >
                            {Object.entries(MetadataBitDepthValues)
                              .filter(([key, bitDepth]) => typeof bitDepth !== 'string')
                              .map(([key, bitDepth]) => (
                                <MenuItem key={bitDepth} value={bitDepth}>
                                  {bitDepth} bit
                                </MenuItem>
                              ))}
                          </Select>
                        </Stack>
                      </Grid>
                    </Grid>
                  </MetadataBoxWrapper>

                  <MetadataBoxWrapper>
                    <Typography mb={1.25}>Lyrics</Typography>
                    <Divider sx={{ mb: 1.25 }} />
                    <TextField
                      disabled={!canEdit}
                      size="small"
                      value={lyrics}
                      multiline
                      rows={3}
                      onChange={e => {
                        setLyrics(e.target.value);
                      }}
                      fullWidth
                      placeholder={'Amazing lyrics should be written here.'}
                      sx={{
                        fontSize: '12px',
                        '& fieldset': { border: 'none', borderRadius: '4px' },
                        '& .MuiInputLabel-root': { fontSize: '12px' },
                        '& .MuiInputBase-root': { backgroundColor: 'transparent', p: 0 },
                        '& .MuiInputBase-input': { fontSize: '12px', p: 0, fontWeight: 'bold' }
                      }}
                      onBlur={() => {
                        if (oldLyrics !== lyrics) {
                          setOldLyrics(lyrics);
                          addEditLyrics(currentVersion!, String(lyrics));
                          enqueueSnackbar('Lyrics edited successfully', { variant: 'success' });
                        }
                      }}
                      InputProps={{}}
                    />
                  </MetadataBoxWrapper>
                </Grid>
              </SimpleBar>
            )}
          </Stack>
        </>
      )}
    </>
  );
}
