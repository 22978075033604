import {
  Autocomplete,
  Box,
  Button,
  Icon,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import * as Yup from 'yup';
import React, { useEffect, useRef, useState } from 'react';
import { Form, useLocation, useParams } from 'react-router-dom';
import SharingDialog from '../../components/SharingDialog';
import { useSongSelector } from '../../store/selectors/song.selector';
import sharingService from '../../services/sharingService';
import { FormikProvider, useFormik } from 'formik';
import { AccessType, SharedUserRoles } from '../../@types/sharing';
import { useUploadFilesSelector } from '../../store/selectors/uploadFiles.selector';
import { ValidationPatterns } from '../../utils/validationPatterns';
import trackEvent from '../../services/trackService';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow_down.svg';
import { ReactComponent as Expand } from '../../assets/icons/expandButton.svg';
import { ReactComponent as Choice } from '../../assets/icons/choice.svg';
import styled from '@emotion/styled';
import { useUploadFilesStore } from '../../store/uploadFIles.store';

export type SongSharingProps = {
  isOwner: boolean;
  disabled: boolean;
  ownerEmail?: string;
  userId?: string;
};

const StyledNoOptionsText = styled('div')(({ theme }) => ({
  fontSize: '12px'
}));

export function SongSharing({ isOwner, ownerEmail, disabled }: SongSharingProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isSharingSongDialogOpen, setIsSharingSongDialogOpen] = useState<boolean>(false);
  const { inviteUser, song, deleteSharedUser } = useSongSelector();
  const { folderId, songId } = useParams();

  const { uploadingSongs } = useUploadFilesSelector();

  const [shareButtonClicked, setShareButtonClicked] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  const { sharedUsers, addPreparedSharedUser, preparedSharedUsers, deletePreparedSharedUser, inputShareType, setInputShareType } =
    useSongSelector();
  const [emails, setEmails] = useState<string[]>([]);
  const [selectedType, setSelectedType] = React.useState('');

  const excludeEmailsPrepared: string[] = preparedSharedUsers?.map(sharedUser => sharedUser.email) ?? [];
  const excludeEmails: string[] = sharedUsers.map(sharedUser => sharedUser.email);
  const allExcludedEmails = [...excludeEmails, ...excludeEmailsPrepared];
  const filteredEmails = emails.filter(email => !allExcludedEmails.includes(email));
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  useEffect(() => {
    if (params.get('isOpenSharing') === 'true') {
      setIsSharingSongDialogOpen(true);
    }
  }, [location.search]);

  let timer: NodeJS.Timeout;

  useEffect(() => {
    const currentUploadingSong = uploadingSongs.find(song => song.songId === songId);

    if (currentUploadingSong) {
      if (currentUploadingSong?.totalProgress === 100) {
        setShowMessage(false);
        showSuccessMessage();
      } else {
        setShowMessage(true);
      }
    } else {
      setShowMessage(false);
      showSuccessMessage();
    }
  }, [uploadingSongs, shareButtonClicked]);

  useEffect(() => {
    sharingService.getSharedUsersAutofill().then(data => setEmails(data.emails));
  }, []);

  const showSuccessMessage = () => {
    setShowSuccessMsg(true);

    timer = setTimeout(() => {
      setShowSuccessMsg(false);
      setShareButtonClicked(false);
      clearTimeout(timer);
    }, 5000);
  };

  const handleSetAccessType = async (sharedUserEmail: string, accessType: string) => {
    await inviteUser({
      email: sharedUserEmail,
      songId: songId ?? '',
      folderId: folderId ?? '',
      type: accessType
    });

    trackEvent('share', { type: 'invite' });
  };

  const handleSetAccessTypeFromPrepared = async () => {
    for (let sharedUser of preparedSharedUsers) {
      inviteUser({
        email: sharedUser.email,
        songId: songId ?? '',
        folderId: folderId ?? '',
        type: sharedUser.accessType
      });
    }

    trackEvent('share', { type: 'invite' });
  };

  const validationSchema = Yup.object({
    email: Yup.string().required('Email is required').matches(ValidationPatterns.email, 'Please enter a valid email'),
    accessType: Yup.string().required().oneOf(Object.values(SharedUserRoles), 'Invalid access type')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      accessType: SharedUserRoles.Downloader
    },
    validationSchema: validationSchema,
    onSubmit: async (values, formikHelpers) => {
      const isEmailValid = await formikHelpers.validateField('email');

      if (isEmailValid?.length) {
        addPreparedSharedUser(values.email, values.accessType);

        formikHelpers.resetForm();
      }
    }
  });

  const { handleSubmit, getFieldProps, setFieldValue } = formik;

  useEffect(() => {
    if (inputShareType !== formik.values.accessType) {
      formik.setFieldValue('accessType', inputShareType);
    }
  }, [inputShareType]);

  useEffect(() => {
    setInputShareType(formik.values.accessType);
  }, [formik.values.accessType]);

  return (
    <>
      {isSharingSongDialogOpen && (
        <SharingDialog
          songId={songId!}
          folderId={folderId!}
          isOwner={isOwner}
          sharedSongsUsers={sharedUsers}
          onClose={() => setIsSharingSongDialogOpen(false)}
          open={isSharingSongDialogOpen}
          ownerEmail={ownerEmail ?? ''}
          showMsg={shareButtonClicked && showMessage}
          showSuccessMsg={shareButtonClicked && showSuccessMsg}
          toggleIsShareButtonClicked={() => setShareButtonClicked(true)}
        />
      )}
      {!isMobile ? (
        <FormikProvider value={formik}>
          <Form noValidate onSubmit={handleSubmit}>
            <Stack position={'relative'}>
              <Stack direction="row" alignItems="center">
                <Stack display={'flex'}>
                  <Stack
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        setOpen(false);
                        formik.handleSubmit();
                      }
                    }}
                    flexDirection="row"
                    sx={{
                      width: '384px',
                      alignItems: 'center',
                      backgroundColor: 'rgba(75, 75, 75, 0.4)',
                      paddingLeft: '12px',
                      height: '40px',
                      borderTopLeftRadius: '32px',
                      borderBottomLeftRadius: '32px',
                      border: '1px solid #FFFFFF'
                    }}
                  >
                    <Autocomplete
                      noOptionsText={<StyledNoOptionsText>No option</StyledNoOptionsText>}
                      fullWidth
                      autoComplete={false}
                      options={filteredEmails}
                      inputValue={formik.values.email}
                      value={formik.values.email}
                      clearOnBlur={false}
                      ListboxProps={{
                        sx: {
                          '& .MuiAutocomplete-option': {
                            paddingY: '0 !important',
                            fontSize: '12px !important',
                            maxHeight: '36px !important'
                          }
                        }
                      }}
                      sx={{
                        '& .MuiAutocomplete-endAdornment': {
                          display: 'none'
                        },
                        '& .MuiAutocomplete-inputRoot': {
                          paddingRight: '5px !important',
                          fontSize: '14px'
                        }
                      }}
                      onChange={(event, value, reason) => {
                        formik.setFieldValue('email', reason === 'clear' ? '' : value);
                      }}
                      renderInput={params => (
                        <div style={{ position: 'relative' }}>
                          <TextField
                            margin="dense"
                            {...params}
                            placeholder="Email to"
                            size="small"
                            autoComplete="off"
                            variant="standard"
                            fullWidth
                            {...getFieldProps('email')}
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                              sx: {
                                '& .MuiAutocomplete-endAdornment': {
                                  display: 'none'
                                }
                              }
                            }}
                            sx={{
                              '& .MuiInputBase-root::before, & .MuiInputBase-root::after': {
                                display: 'none'
                              }
                            }}
                          />
                        </div>
                      )}
                    />
                    <Select
                      onClose={() => {
                        setOpen(false);
                      }}
                      onOpen={() => {
                        setOpen(true);
                      }}
                      open={open}
                      {...getFieldProps('accessType')}
                      IconComponent={ArrowDown}
                      renderValue={selected => {
                        switch (selected) {
                          case SharedUserRoles.Viewer:
                            setSelectedType(SharedUserRoles.Viewer);
                            return AccessType.CanView;
                          case SharedUserRoles.Downloader:
                            setSelectedType(SharedUserRoles.Downloader);
                            return AccessType.CanDownload;
                          case SharedUserRoles.Editor:
                            setSelectedType(SharedUserRoles.Editor);
                            return AccessType.CanEdit;
                          default:
                            return '';
                        }
                      }}
                      sx={{
                        color: '#ABABAB',
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        padding: '0px',
                        '& .MuiSelect-select': {
                          padding: '0px',
                          fontSize: '14px !important'
                        },
                        '.MuiOutlinedInput-notchedOutline': {
                          border: 0
                        },
                        '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                          border: 0
                        },
                        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          border: 0
                        }
                      }}
                    >
                      <MenuItem
                        sx={{
                          position: 'relative',
                          paddingTop: '3px',
                          paddingLeft: '13px',
                          fontSize: '14px',
                          display: 'flex',
                          alignItems: 'flex-start',
                          width: '164px'
                        }}
                        value={SharedUserRoles.Viewer}
                      >
                        {AccessType.CanView}
                        <Typography sx={{ fontSize: '11px', color: '#757575', position: 'absolute', top: '50%', left: '13px' }}>
                          no downloading
                        </Typography>
                        {selectedType === SharedUserRoles.Viewer ? (
                          <Stack
                            sx={{
                              position: 'absolute',
                              right: '5px',
                              top: '50%',
                              transform: 'translateY(-50%)',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              width: '20px',
                              height: '20px'
                            }}
                          >
                            <Choice opacity={'50%'} />
                          </Stack>
                        ) : null}
                      </MenuItem>
                      <MenuItem
                        sx={{
                          position: 'relative',
                          fontSize: '14px',
                          paddingTop: '3px',
                          paddingLeft: '13px',
                          display: 'flex',
                          alignItems: 'center',
                          width: '164px'
                        }}
                        value={SharedUserRoles.Downloader}
                      >
                        {AccessType.CanDownload}
                        {selectedType === SharedUserRoles.Downloader ? (
                          <Stack
                            sx={{
                              position: 'absolute',
                              right: '5px',
                              top: '50%',
                              transform: 'translateY(-50%)',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              width: '20px',
                              height: '20px'
                            }}
                          >
                            <Choice opacity={'50%'} />
                          </Stack>
                        ) : null}
                      </MenuItem>
                      <MenuItem
                        sx={{
                          position: 'relative',
                          fontSize: '14px',
                          paddingTop: '3px',
                          paddingLeft: '13px',
                          display: 'flex',
                          alignItems: 'flex-start',
                          width: '164px'
                        }}
                        value={SharedUserRoles.Editor}
                      >
                        {AccessType.CanEdit}
                        <Typography sx={{ fontSize: '11px', color: '#757575', position: 'absolute', top: '50%', left: '13px' }}>
                          can add/delete files
                        </Typography>
                        {selectedType === SharedUserRoles.Editor ? (
                          <Stack
                            sx={{
                              position: 'absolute',
                              right: '5px',
                              top: '50%',
                              transform: 'translateY(-50%)',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              width: '20px',
                              height: '20px'
                            }}
                          >
                            <Choice opacity={'50%'} />
                          </Stack>
                        ) : null}
                      </MenuItem>
                    </Select>
                  </Stack>
                </Stack>
                <Button
                  onClick={async () => {
                    const values = formik.values;
                    const formikHelpers = { resetForm: formik.resetForm };

                    if (preparedSharedUsers.length === 0) {
                      if (ValidationPatterns.email.test(values.email)) {
                        handleSetAccessType(values.email, values.accessType);
                        setShareButtonClicked(true);
                        formikHelpers.resetForm();
                      } else {
                        enqueueSnackbar('Please enter valid email', { variant: 'warning' });
                      }
                    } else {
                      handleSetAccessTypeFromPrepared();
                      setShareButtonClicked(true);
                      formikHelpers.resetForm();
                    }
                  }}
                  disabled={disabled}
                  sx={{
                    border: '1px solid #FFFFFF',
                    fontSize: '14px',
                    borderRadius: 0,
                    lineHeight: '18px',
                    borderLeft: 0,
                    height: '40px',
                    width: '80px',
                    paddingBlock: '11px',
                    '&.Mui-disabled': {
                      backgroundColor: 'info.main',
                      color: '#FFFF',
                      opacity: '50%'
                    }
                  }}
                  type="button"
                  color="info"
                  variant="contained"
                >
                  Share
                </Button>
                <Button
                  disabled={disabled}
                  onClick={() => setIsSharingSongDialogOpen(true)}
                  style={{ minWidth: '40px', minHeight: '40px' }}
                  sx={{
                    border: '1px solid #FFFFFF',
                    borderLeft: 0,
                    borderTopRightRadius: '32px',
                    borderBottomRightRadius: '32px',
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    padding: 0,
                    '&.Mui-disabled': {
                      backgroundColor: 'info.main',
                      color: '#FFFF',
                      opacity: '50%'
                    }
                  }}
                  color="info"
                  variant="contained"
                >
                  <Expand />
                </Button>
              </Stack>

              {!isSharingSongDialogOpen && shareButtonClicked && showMessage && (
                <Typography fontSize={14} fontWeight={400} mt={1} position={'absolute'} top={'35px'}>
                  Your files will send as soon as upload is complete!
                </Typography>
              )}

              {!isSharingSongDialogOpen && shareButtonClicked && showSuccessMsg && (
                <Stack direction={'row'} mt={1} alignItems={'center'} position={'absolute'} top={'35px'}>
                  <Typography fontSize={14} fontWeight={400} mr={0.5}>
                    Sent
                  </Typography>
                  <Box position={'relative'} height={'14px'} width={'14px'}>
                    <img src={'/assets/success.svg'} height={'14px'} width={'14px'} alt="song" />
                  </Box>
                </Stack>
              )}
              <Stack
                direction="row"
                mt={1}
                px={1}
                gap={1}
                maxWidth={'480px'}
                maxHeight={'56px'}
                overflow={'auto'}
                flexWrap={'wrap'}
                position={'absolute'}
                top={shareButtonClicked ? '60px' : '35px'}
              >
                {preparedSharedUsers.map(sharedUser => (
                  <Stack
                    sx={{ background: '#4B4B4B66' }}
                    height={'24px'}
                    direction="row"
                    key={sharedUser.email}
                    p={0.5}
                    pt={0.4}
                    borderRadius={4}
                    position={'relative'}
                  >
                    <Typography fontSize={14} lineHeight={'17px'} fontWeight={400}>
                      {sharedUser.email}
                    </Typography>
                    <Button
                      sx={{
                        background: 'linear-gradient(270deg, #273640 56.85%, rgba(75, 75, 75, 0) 95.55%)',
                        position: 'absolute',
                        minWidth: 'unset',
                        width: '31px',
                        height: '24px',
                        top: 0,
                        right: 0,
                        borderRadius: 4,
                        justifyContent: 'right'
                      }}
                      onClick={() => deletePreparedSharedUser(sharedUser.email)}
                    >
                      <img src={'/assets/deleteIcon.svg'} alt="song" />
                    </Button>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          </Form>
        </FormikProvider>
      ) : (
        <Button
          size="small"
          color="info"
          variant="contained"
          onClick={() => setIsSharingSongDialogOpen(true)}
          disableElevation={true}
          sx={{
            border: '1px solid #FFFFFF',
            height: '35px',
            width: '62px',
            padding: '10px 12px',
            fontSize: '14px',
            minWidth: 'unset',
            '&.Mui-disabled': {
              backgroundColor: 'info.main',
              color: '#FFFF',
              opacity: '50%'
            }
          }}
        >
          Share
        </Button>
      )}
    </>
  );
}
