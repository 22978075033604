import { create } from 'zustand';

type SidebarState = {
  isSidebarOpened: boolean;
  sidebarWidth: number;
};

type SidebarActions = {
  setSidebarWidth: (size: number) => void;
  toggleSidebar: () => void;
};

export const useSidebarStore = create<SidebarState & SidebarActions>((set, get) => ({
  isSidebarOpened: false,
  sidebarWidth: 0,
  toggleSidebar: () => {
    set({ isSidebarOpened: !get().isSidebarOpened });
  },
  setSidebarWidth: size => set({ sidebarWidth: size })
}));
