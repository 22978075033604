import { useAuth0 } from '@auth0/auth0-react';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NotificationType } from '../../@types/notifications';
import { Wavesurfer } from '../../components/Wavesurfer';
import notificationService from '../../services/notificationService';
import { useSongSelector } from '../../store/selectors/song.selector';
import { formatTime } from '../../utils/helpers';

export type SongPlayerProps = {
  isOwner: boolean;
  waveSurfer: any;
  playlistEventEmitter: any;
  setIsSongPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  setIsStemsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  isStemsPlaying: boolean;
  isSongPlaying: boolean;
};

export function SongPlayer({
  isOwner,
  waveSurfer,
  playlistEventEmitter,
  setIsSongPlaying,
  setIsStemsPlaying,
  isStemsPlaying,
  isSongPlaying
}: SongPlayerProps) {
  const { songId } = useParams();
  const { currentVersion, song } = useSongSelector();
  const [currentTimeSong, setCurrentTimeSong] = useState<number>(0);
  const { user } = useAuth0();

  useEffect(() => {
    if (waveSurfer) {
      waveSurfer?.on('finish', () => {
        setIsSongPlaying(false);
      });

      waveSurfer?.on('timeupdate', () => {
        setCurrentTimeSong(waveSurfer.getCurrentTime());
      });
    }

    return () => {
      waveSurfer?.un('finish', () => {});
    };
  }, [waveSurfer]);

  return (
    <Grid
      container
      direction="row"
      columnGap={{ xs: 2, sm: 1 }}
      px={{ xs: 1.75, sm: 2.3 }}
      py={{ xs: 2, sm: 2.3 }}
      sx={{
        background: { xs: '#01223B', sm: 'rgba(74, 74, 74, 0.30)' },
        boxShadow: '4px 4px 20px 0px rgba(0, 0, 0, 0.20)',
        borderRadius: '6px'
      }}
    >
      <Grid item xs="auto">
        <IconButton
          sx={{ height: '28px', width: '28px', minWidth: 'unset', display: { xs: 'none', sm: 'inline-flex' } }}
          onClick={() => {
            waveSurfer?.skip(-10);
          }}
        >
          <SkipPreviousIcon />
        </IconButton>
        <Button
          variant="contained"
          color="info"
          sx={{ height: { xs: '40px', sm: '28px' }, width: { xs: '40px', sm: '28px' }, minWidth: 'unset', marginX: 0.5, boxShadow: 'none' }}
          disableElevation={true}
          onClick={() => {
            if (isStemsPlaying) {
              setIsStemsPlaying(false);
              playlistEventEmitter.emit('pause');
            }

            if (!waveSurfer?.isPlaying()) {
              if (!isOwner && !!user) {
                notificationService.createNotification(NotificationType.ListenSong, songId!);

              }
              
            }

            waveSurfer?.playPause();

            setIsSongPlaying(isPlay => !isPlay);
          }}
        >
          {isSongPlaying ? <PauseIcon /> : <PlayArrowIcon fontSize="medium" />}
        </Button>
        <IconButton
          sx={{ height: '28px', width: '28px', minWidth: 'unset', display: { xs: 'none', sm: 'inline-flex' } }}
          onClick={() => {
            waveSurfer?.skip(10);
          }}
        >
          <SkipNextIcon />
        </IconButton>
      </Grid>
      <Grid item xs position={'relative'}>
        {waveSurfer && (
          <>
            <Typography
              fontSize={10}
              lineHeight={'13px'}
              sx={{ position: 'absolute', background: '#151515', borderRadius: 1, px: 0.5, bottom: '-50%' }}
            >
              {formatTime(Math.ceil(currentTimeSong))}
            </Typography>
            <Typography
              fontSize={10}
              lineHeight={'13px'}
              sx={{
                position: 'absolute',
                background: '#151515',
                borderRadius: 1,
                px: 0.5,
                bottom: '-50%',
                right: 0
              }}
            >
              {formatTime(Math.ceil(waveSurfer?.getDuration() ?? 0))}
            </Typography>
          </>
        )}
        <Wavesurfer currentVersion={currentVersion} song={song} />
      </Grid>
    </Grid>
  );
}
