import { create } from 'zustand';
import { Folder, SidebarFolder } from '../@types/folders';
import { MoveSongRequest, Song } from '../@types/songs';
import folderService from '../services/folderService';
import songsService from '../services/songsService';
import sharingService from '../services/sharingService';
import { SharedUserInfo, ShareFolderType } from '../@types/sharing';

type FoldersStoreState = {
  folders: SidebarFolder[];
  sharedFolderUsers: SharedUserInfo[];
  foldersError: Error | string | null;
  isFoldersLoading: boolean;
  isRenameLoading: string;
};

type FoldersStoreActions = {
  getFolders: () => Promise<SidebarFolder[]>;
  createFolder: (name: string, parentFolderId?: string, userName?: string) => Promise<SidebarFolder | undefined>;
  deleteFolder: (folderId: string) => Promise<void>;
  renameFolder: (folderId: string, newName: string, userName?: string) => Promise<void>;
  renameSongInSidebar: (folderId: string, songId: string, newName: string, userName?: string) => void;
  updateSong: (folderId: string, songId: string, userName?: string) => void;
  renameSong: (folderId: string, songId: string, newName: string, userName?: string) => Promise<void>;
  createSongInFolder: (folderId: string, song: Song, userName?: string) => void;
  deleteFolderSong: (folderId: string, songId: string) => void;
  moveFolderSong: (data: MoveSongRequest, userName?: string) => Promise<void>;
  shareFolderByInvite: (data: ShareFolderType) => Promise<void>;
  getFolderSharedUsers: (folderId: string) => Promise<void>;
  deleteFolderSharedUser: (email: string, folderId: string) => Promise<void>;
  toggleFolder: (folderId: string) => void;
  setFolders: (folders: SidebarFolder[]) => void;
  openFolder: (folderId: string) => void;
  moveFolder: (draggedFolderId: string, targetFolderId: string, userName?: string) => Promise<void>;
  moveToRootFolder: (draggedFolderId: string, userName?: string) => Promise<void>;
};
export const defaultFolderName = 'default_folder_for_uploads';
export const useFoldersStore = create<FoldersStoreState & FoldersStoreActions>((set, get, store) => ({
  folders: [],
  sharedFolderUsers: [],
  foldersError: null,
  isFoldersLoading: false,
  isRenameLoading: '',
  setFolders: (folders: SidebarFolder[]) => {
    set({
      folders
    });
  },
  getFolders: async () => {
    try {
      set({ isFoldersLoading: true });

      const hasSongsInFolder = (folders: Folder[]): boolean => {
        for (const folder of folders) {
          if (folder.songs && folder.songs.length > 0) {
            return true;
          }

          if (folder.children && folder.children.length > 0) {
            if (hasSongsInFolder(folder.children)) {
              return true;
            }
          }
        }

        return false;
      };

      const folders = await folderService.getFolders();

      const hasSongs = hasSongsInFolder(folders);

      if (!hasSongs) {
        folders.push({
          id: 'example',
          name: 'example',
          songs: [
            {
              id: 'sample_example',
              name: 'Dolor (sample project)',
              folderId: 'example',
              accessType: 'Viewer'
            }
          ],
          children: []
        });
      }

      const addIsOpenProperty = (folders: Folder[]): SidebarFolder[] => {
        return folders.map(folder => ({
          ...folder,
          isOpen: false,
          children: addIsOpenProperty(folder.children)
        }));
      };

      const modifiedFolders = addIsOpenProperty(folders);

      set({ folders: modifiedFolders, isFoldersLoading: false, foldersError: null });

      return modifiedFolders;
    } catch (e: any) {
      set({ folders: [], foldersError: e.message, isFoldersLoading: false });
      return [];
    }
  },
  createFolder: async (name, parentFolderId, userName) => {
    try {
      const folder = await folderService
        .createFolder(name, parentFolderId)
        .then(f => ({ ...f, accessType: undefined, createdByName: userName }));

      set(state => {
        const addFolderToTree = (folders: SidebarFolder[], parentFolderId?: string): SidebarFolder[] => {
          return folders.map(f => {
            if (f.id === parentFolderId) {
              return {
                ...f,
                children: [...f.children, { ...folder, isOpen: false, children: [] }]
              };
            }
            return {
              ...f,
              children: addFolderToTree(f.children, parentFolderId)
            };
          });
        };

        const updatedFolders = parentFolderId
          ? addFolderToTree(state.folders, parentFolderId)
          : [...state.folders, { ...folder, isOpen: false, children: [] }];

        return { folders: updatedFolders, foldersError: null };
      });

      return { ...folder, isOpen: false, children: [] };
    } catch (e: any) {
      set(state => ({
        folders: [...state.folders],
        foldersError: e.message
      }));
    }
  },
  deleteFolder: async (folderId: string) => {
    try {
      await folderService.deleteFolder(folderId);

      const deleteFolderFromStructure = (folders: SidebarFolder[], folderId: string): SidebarFolder[] => {
        return folders
          .filter(folder => folder.id !== folderId)
          .map(folder => ({
            ...folder,
            children: folder.children ? deleteFolderFromStructure(folder.children, folderId) : []
          }));
      };

      set(state => ({
        folders: deleteFolderFromStructure(state.folders, folderId),
        foldersError: null
      }));
    } catch (e: any) {
      set(state => ({
        folders: [...state.folders],
        foldersError: e.message
      }));
    }
  },
  renameFolder: async (folderId, newName, userName) => {
    const updateFolderName = (folders: SidebarFolder[]): SidebarFolder[] => {
      return folders.map(folder => {
        if (folder.id === folderId) {
          return { ...folder, name: newName, updatedByName: userName, updatedAt: new Date().toISOString() };
        }

        if (folder.children) {
          return {
            ...folder,
            children: updateFolderName(folder.children)
          };
        }

        return folder;
      });
    };

    try {
      set({ isRenameLoading: folderId });

      await folderService.renameFolder(folderId, newName);

      set(state => ({
        folders: updateFolderName(state.folders),
        foldersError: null,
        isRenameLoading: ''
      }));
    } catch (e: any) {
      // Handle any errors during renaming
      set({
        folders: [...get().folders],
        foldersError: e.message
      });
    }
  },
  renameSongInSidebar: async (folderId, songId, newName, userName) => {
    try {
      const renameSong = (folders: SidebarFolder[], folderId: string, songId: string, newName: string): SidebarFolder[] => {
        return folders.map(folder => {
          if (folder.id === folderId) {
            const updatedSongs = folder.songs.map(song =>
              song.id === songId ? { ...song, name: newName, updatedByName: userName, updatedAt: new Date().toISOString() } : song
            );

            return {
              ...folder,
              songs: updatedSongs
            };
          } else if (folder.children) {
            return {
              ...folder,
              children: renameSong(folder.children, folderId, songId, newName)
            };
          }

          return folder;
        });
      };

      set(state => ({
        folders: renameSong(state.folders, folderId, songId, newName)
      }));
    } catch (e: any) {
      set({ foldersError: e.message });
    }
  },
  renameSong: async (folderId, songId, newName, userName) => {
    try {
      get().renameSongInSidebar(folderId, songId, newName, userName);
    } catch (e: any) {
      set({ foldersError: e.message });
    }
  },
  createSongInFolder: (folderId: string, song: Song, userName?: string) => {
    let songWithDate = { ...song, createdByName: userName, createdAt: new Date().toISOString() };
    console.log(songWithDate);

    const addSongToFolder = (folders: SidebarFolder[], folderId: string, song: Song): SidebarFolder[] => {
      return folders.map(folder => {
        if (folder.id === folderId) {
          const songExists = folder.songs.some(existingSong => existingSong.id === song.id);

          if (!songExists) {
            return {
              ...folder,
              songs: [...folder.songs, song]
            };
          }
          return folder;
        } else if (folder.children) {
          return {
            ...folder,
            children: addSongToFolder(folder.children, folderId, song)
          };
        }

        return folder;
      });
    };

    set(state => ({
      folders: addSongToFolder(state.folders, folderId, songWithDate)
    }));
  },
  deleteFolderSong: (folderId: string, songId: string) => {
    const deleteSongFromFolder = (folders: SidebarFolder[], folderId: string, songId: string): SidebarFolder[] => {
      return folders.map(folder => {
        if (folder.id === folderId) {
          return {
            ...folder,
            songs: folder.songs.filter(song => song.id !== songId)
          };
        } else if (folder.children) {
          return {
            ...folder,
            children: deleteSongFromFolder(folder.children, folderId, songId)
          };
        }

        return folder;
      });
    };

    set(state => ({
      folders: deleteSongFromFolder(state.folders, folderId, songId)
    }));
  },
  shareFolderByInvite: async data => {
    try {
      await sharingService.shareFolderByInvite(data);

      await get().getFolderSharedUsers(data.folderId);
    } catch (error) {}
  },
  getFolderSharedUsers: async folderId => {
    try {
      const sharedFolderUsers = await sharingService.getSharedUsersByFolderId(folderId);

      set({ sharedFolderUsers, foldersError: null });
    } catch (e: any) {
      set({ sharedFolderUsers: [], foldersError: e.message });
    }
  },
  deleteFolderSharedUser: async (email: string, folderId: string) => {
    try {
      await sharingService.deleteFolderSharedUser(email, folderId);

      set({ sharedFolderUsers: get().sharedFolderUsers.filter(sharedUser => sharedUser.email !== email) });
    } catch (e: any) {
      set({ foldersError: e.message });
    }
  },
  toggleFolder: (folderId: string) => {
    set(state => {
      const updateFolders = (folders: SidebarFolder[], targetId: string | null, level: number): SidebarFolder[] => {
        return folders.map(folder => {
          if (folder.id === targetId) {
            return {
              ...folder,
              isOpen: !folder.isOpen,
              children: folder.isOpen ? closeAllChildren(folder.children) : updateFolders(folder.children, targetId, level + 1)
            };
          }

          const shouldClose = targetId !== null && getFolderLevel(targetId, state.folders) === level && folder.id !== 'shared_folder';
          return {
            ...folder,
            isOpen: shouldClose ? false : folder.isOpen,
            children: updateFolders(folder.children, targetId, level + 1)
          };
        });
      };

      const closeAllChildren = (folders: SidebarFolder[]): SidebarFolder[] => {
        return folders.map(folder => {
          return {
            ...folder,
            isOpen: false,
            children: closeAllChildren(folder.children)
          };
        });
      };

      const getFolderLevel = (id: string, folders: SidebarFolder[], level: number = 0): number => {
        for (const folder of folders) {
          if (folder.id === id) {
            return level;
          }
          const childLevel = getFolderLevel(id, folder.children, level + 1);
          if (childLevel >= 0) {
            return childLevel;
          }
        }
        return -1;
      };

      return { folders: updateFolders(state.folders, folderId, 0) };
    });
  },
  openFolder: (folderId: string) =>
    set(state => {
      const openFolderById = (folders: SidebarFolder[]): boolean => {
        for (const folder of folders) {
          if (folder.id === folderId) {
            folder.isOpen = true;
            return true;
          }

          if (folder.children.length > 0) {
            const childOpened = openFolderById(folder.children);
            if (childOpened) {
              folder.isOpen = true;
              return true;
            }
          }
        }
        return false;
      };

      const updatedFolders = [...state.folders];

      openFolderById(updatedFolders);

      return { folders: updatedFolders };
    }),
  moveToRootFolder: async (draggedFolderId, userName) => {
    await folderService.moveFolder(draggedFolderId, 'root');

    set(state => {
      const findAndRemoveFolder = (
        folderId: string,
        folderList: SidebarFolder[]
      ): { folder: SidebarFolder | null; updatedFolders: SidebarFolder[] } => {
        for (let i = 0; i < folderList.length; i++) {
          const folder = folderList[i];

          if (folder.id === folderId) {
            const updatedFolders = [...folderList];
            updatedFolders.splice(i, 1);
            const updatedFolder = {
              ...folder,
              updatedByName: userName,
              updatedAt: new Date().toISOString()
            };

            return { folder: updatedFolder, updatedFolders };
          }

          if (folder.children.length > 0) {
            const { folder: foundFolder, updatedFolders } = findAndRemoveFolder(folderId, folder.children);
            if (foundFolder) {
              const updatedFolderList = [...folderList];
              updatedFolderList[i] = { ...folder, children: updatedFolders };
              return { folder: foundFolder, updatedFolders: updatedFolderList };
            }
          }
        }
        return { folder: null, updatedFolders: folderList };
      };

      const { folder: draggedFolder, updatedFolders } = findAndRemoveFolder(draggedFolderId, state.folders);

      if (!draggedFolder) {
        console.warn(`Folder with ID ${draggedFolderId} not found`);
        return { folders: state.folders };
      }

      const newFolders = [...updatedFolders, draggedFolder];

      return { folders: newFolders };
    });
  },
  moveFolder: async (draggedFolderId, targetFolderId, userName) => {
    await folderService.moveFolder(draggedFolderId, targetFolderId);

    set(state => {
      const findAndRemoveFolder = (
        folderId: string,
        folderList: SidebarFolder[]
      ): { folder: SidebarFolder | null; updatedFolders: SidebarFolder[] } => {
        for (let i = 0; i < folderList.length; i++) {
          const folder = folderList[i];

          if (folder.id === folderId) {
            const updatedFolders = [...folderList];
            updatedFolders.splice(i, 1);
            const updatedFolder = {
              ...folder,
              updatedByName: userName,
              updatedAt: new Date().toISOString()
            };

            return { folder: updatedFolder, updatedFolders };
          }

          if (folder.children.length > 0) {
            const { folder: foundFolder, updatedFolders } = findAndRemoveFolder(folderId, folder.children);
            if (foundFolder) {
              const updatedFolderList = [...folderList];
              updatedFolderList[i] = { ...folder, children: updatedFolders };
              return { folder: foundFolder, updatedFolders: updatedFolderList };
            }
          }
        }
        return { folder: null, updatedFolders: folderList };
      };

      const insertFolder = (folderToInsert: SidebarFolder, targetFolderId: string, folderList: SidebarFolder[]): SidebarFolder[] => {
        return folderList.map(folder => {
          if (folder.id === targetFolderId) {
            return { ...folder, children: [...folder.children, folderToInsert] };
          }

          if (folder.children.length > 0) {
            return {
              ...folder,
              children: insertFolder(folderToInsert, targetFolderId, folder.children)
            };
          }

          return folder;
        });
      };

      const { folder: draggedFolder, updatedFolders } = findAndRemoveFolder(draggedFolderId, state.folders);

      if (!draggedFolder) {
        return { folders: state.folders };
      }

      const newFolders = insertFolder(draggedFolder, targetFolderId, updatedFolders);

      return { folders: newFolders };
    });
  },
  moveFolderSong: async ({ songId, currentFolderId, newFolderId }, userName) => {
    await songsService.moveSong({ songId, currentFolderId, newFolderId });

    set(state => {
      const findAndRemoveSong = (songId: string, folderId: string, folderList: SidebarFolder[]): SidebarFolder[] => {
        return folderList.map(folder => {
          if (folder.id === folderId) {
            return {
              ...folder,
              songs: folder.songs.filter(song => song.id !== songId)
            };
          }

          if (folder.children.length > 0) {
            return {
              ...folder,
              children: findAndRemoveSong(songId, folderId, folder.children)
            };
          }

          return folder;
        });
      };

      const insertSong = (songToInsert: Song, newFolderId: string, folderList: SidebarFolder[]): SidebarFolder[] => {
        return folderList.map(folder => {
          if (folder.id === newFolderId) {
            const updatedSong = {
              ...songToInsert,
              updatedByName: userName,
              updatedAt: new Date().toISOString()
            };
            return {
              ...folder,
              songs: [...folder.songs, updatedSong]
            };
          }

          if (folder.children.length > 0) {
            return {
              ...folder,
              children: insertSong(songToInsert, newFolderId, folder.children)
            };
          }

          return folder;
        });
      };

      let songToMove: Song | null = null;

      const findSong = (folderId: string, folderList: SidebarFolder[]): Song | null => {
        for (let folder of folderList) {
          if (folder.id === folderId) {
            const song = folder.songs.find(song => song.id === songId);
            if (song) return song;
          }

          if (folder.children.length > 0) {
            const foundSong = findSong(folderId, folder.children);
            if (foundSong) return foundSong;
          }
        }
        return null;
      };

      songToMove = findSong(currentFolderId, state.folders);

      if (!songToMove) return { folders: state.folders };

      songToMove.folderId = newFolderId;

      const updatedFolders = findAndRemoveSong(songId, currentFolderId, state.folders);

      const newFolders = insertSong(songToMove, newFolderId, updatedFolders);

      return { folders: newFolders };
    });
  },
  updateSong: async (folderId, songId, userName) => {
    try {
      const updateSong = (folders: SidebarFolder[], folderId: string, songId: string): SidebarFolder[] => {
        return folders.map(folder => {
          if (folder.id === folderId) {
            const updatedSongs = folder.songs.map(song =>
              song.id === songId ? { ...song, updatedByName: userName, updatedAt: new Date().toISOString() } : song
            );

            return {
              ...folder,
              songs: updatedSongs
            };
          } else if (folder.children) {
            return {
              ...folder,
              children: updateSong(folder.children, folderId, songId)
            };
          }

          return folder;
        });
      };

      set(state => ({
        folders: updateSong(state.folders, folderId, songId)
      }));
    } catch (e: any) {
      set({ foldersError: e.message });
    }
  }
}));
