import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { usePlaylistStore } from '../../store/players.store';
import StemsPlayCustomTime from './StemsPlayCustomTime';
// @ts-ignore
import WaveformPlaylist from 'waveform-playlist';
import { SoloMutedStems } from '../../@types/songs';
import { useSongSelector } from '../../store/selectors/song.selector';
import { formatTime } from '../../utils/helpers';
// @ts-ignore

export type PlaylistProps = {
  stems: { name: string; src: string }[];
  onStopPlaying: () => void;
  versionId: string;
  soloMutedStems: Map<string, SoloMutedStems>;
  colors?: any;
};

function pixelsToSeconds(pixels: number, resolution: number, sampleRate: number) {
  return (pixels * resolution) / sampleRate;
}

const handlePlayHeadDrag = (renderPlaylist: any) => {
  const tracksElement = document.getElementsByClassName('playlist-tracks')[0];
  if (!tracksElement) return;
  let isPlaying = false;
  let isDragging = false;
  const mousedownHandler = async (e: Event) => {
    isDragging = true;
    const mouseEvent = e as MouseEvent;
    const tracksElementX = tracksElement.getBoundingClientRect()?.left || 0;

    if (renderPlaylist.isPlaying()) {
      isPlaying = true;
      await renderPlaylist.pause();
    }
    mouseEvent.preventDefault();
    const startTime = Math.min(
      pixelsToSeconds(mouseEvent.offsetX, renderPlaylist.samplesPerPixel, renderPlaylist.sampleRate),
      renderPlaylist.duration
    );
    renderPlaylist.ee.emit('select', startTime, startTime, null);
    const mouseupHandler = (e: Event) => {
      e.preventDefault();
      isDragging = false;
      const mouseEvent = e as MouseEvent;
      const endTime = Math.min(
        pixelsToSeconds(
          Math.max(mouseEvent.clientX - (tracksElementX - tracksElement.scrollLeft), 0),
          renderPlaylist.samplesPerPixel,
          renderPlaylist.sampleRate
        ),
        renderPlaylist.duration
      );

      if (isPlaying) {
        setTimeout(async () => {
          if (isPlaying && !isDragging) {
            renderPlaylist.play();
            isPlaying = false;
          }
        }, 100);
      }
      // renderPlaylist.ee.emit('select', endTime, endTime, null);
      document.removeEventListener('mouseup', mouseupHandler);
      document.removeEventListener('mousemove', mousemoveHandler);
    };

    document.addEventListener('mouseup', mouseupHandler);
    const mousemoveHandler = (e: Event) => {
      e.preventDefault();
      const mouseEvent = e as MouseEvent;
      const currTime = Math.min(
        pixelsToSeconds(
          Math.max(mouseEvent.clientX - (tracksElementX - tracksElement.scrollLeft), 0),
          renderPlaylist.samplesPerPixel,
          renderPlaylist.sampleRate
        ),
        renderPlaylist.duration
      );

      renderPlaylist.ee.emit('select', currTime, currTime, null);
    };

    document.addEventListener('mousemove', mousemoveHandler);
  };
  tracksElement?.addEventListener('mousedown', mousedownHandler);
  return mousedownHandler;
};

export function Playlist({ stems, onStopPlaying, versionId, soloMutedStems, colors }: PlaylistProps) {
  const theme = useTheme();
  let isSafari = useMemo(() => /^((?!chrome|android).)*safari/i.test(navigator.userAgent), []);
  const { isOriginalStems, isCompressedExists } = useSongSelector();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobileLandscape = useMediaQuery('(max-height: 500px) and (orientation: landscape)');
  const soloMutedStemsRef = useRef(soloMutedStems);

  // Update the ref whenever propValue changes
  useEffect(() => {
    soloMutedStemsRef.current = soloMutedStems;
  }, [soloMutedStems]);
  const playlistState = usePlaylistStore(state => state.playListStates.find(state => state.versionId === versionId));
  const playlistStates = usePlaylistStore(state => state.playListStates);
  const init = usePlaylistStore(state => state.initPlaylist);
  const incrLoadedStemsCount = usePlaylistStore(state => state.incrLoadedStemsCount);
  const handleIsStemsRendered = usePlaylistStore(state => state.handleIsStemsRendered);
  const setSoloMutedStems = usePlaylistStore(state => state.setSoloMutedStems);
  const dragHandler = useRef<((e: Event) => Promise<void>) | undefined>();
  const [currentTime, setCurrentTime] = useState(0);
  const [playlistDuration, setPlayListDuration] = useState(0);
  const playListRef = useRef();
  const handleFinish = useCallback(() => {
    setCurrentTime(0);
    onStopPlaying();
  }, [onStopPlaying]);

  const handleTimeUpdate = useCallback((time: number) => {
    setCurrentTime(time);
  }, []);

  const handleBlurAllInputs = () => {
    const inputs = document.querySelectorAll('input, textarea');
    inputs.forEach(input => {
      if (input instanceof HTMLInputElement || input instanceof HTMLTextAreaElement) {
        input.blur();
      }
    });
  };

  const destroy = () => {
    setSoloMutedStems(soloMutedStemsRef.current, versionId);
    playlistState?.eventEmitter.emit('pause');
    playlistState?.eventEmitter.removeListener('finished', handleFinish);
    playlistState?.eventEmitter.removeListener('timeupdate', handleTimeUpdate);
    if (dragHandler.current) {
      document.getElementsByClassName('playlist-tracks')[0]?.removeEventListener('mousedown', dragHandler.current);
    }
    let playlistNode = document.getElementsByClassName('playlist');

    if (playlistNode) {
      playlistNode?.item(0)?.parentElement?.removeChild(playlistNode?.item(0)!);
    }
  };
  const isStemsExit = useMemo(() => {
    return !!stems?.length;
  }, [stems]);
  useEffect(() => {
    if (playlistState?.playlist) {
      playListRef.current = playlistState.playlist;
      document.getElementById('waveform-container')?.appendChild(playlistState.playlist.rootNode);
      dragHandler.current = handlePlayHeadDrag(playListRef.current);
      setCurrentTime(playlistState?.playlist?.pausedAt || 0);
      playlistState?.playlist.ee.on('finished', handleFinish);
      playlistState?.playlist.ee.on('timeupdate', handleTimeUpdate);
      return;
    }
    if (!isStemsExit) {
      return;
    }
    const multiTrackStems = stems.map(stem => ({
      src: stem.src,
      name: stem.name,
      usePolyfillReader: !isSafari && stem.name.endsWith('.aiff') && (!isCompressedExists || isOriginalStems)
    }));
    const waveFormPlayListConfig = {
      samplesPerPixel: 16384,
      mono: true,
      waveHeight: isMobile || isMobileLandscape ? 116 : 61,
      container: document.getElementById('waveform-container'),
      timescale: true,
      state: 'cursor',
      controls: {
        show: false,
        width: 0
      },
      colors: {
        waveOutlineColor: colors?.waveOutlineColor || '#151515',
        timeColor: 'white',
        fadeColor: 'blue'
      },
      isAutomaticScroll: true,
      barWidth: isMobile ? 2 : 1,
      barGap: isMobile ? 2 : 1,
      zoomLevels: isMobile ? [8192, 10280, 12384, 16384] : [64, 96, 128, 256, 512, 1024, 2048, 4096, 6048, 8192, 10280, 12384, 16384]
    };
    const renderPlaylist: any = WaveformPlaylist(waveFormPlayListConfig, playlistState?.eventEmitter);

    renderPlaylist
      .load(multiTrackStems, () => incrLoadedStemsCount(versionId))
      .then(async () => {
        init(renderPlaylist, versionId);
        dragHandler.current = await handlePlayHeadDrag(renderPlaylist);
        setPlayListDuration(renderPlaylist.duration);
        renderPlaylist.ee.on('finished', handleFinish);
        renderPlaylist.ee.on('timeupdate', handleTimeUpdate);
      });
    playListRef.current = renderPlaylist;
    return () => {
      destroy();
    };
  }, [versionId, isStemsExit]);
  useEffect(() => {
    if (playListRef.current) {
      // @ts-ignore
      setPlayListDuration(playListRef.current.duration);
    }
    if (!stems.length) {
      destroy();
    }
  }, [stems]);

  return (
    <Box onClick={handleBlurAllInputs}>
      <StemsPlayCustomTime currentTime={formatTime(Math.ceil(currentTime))} totalTime={formatTime(Math.ceil(playlistDuration))} />
      <Box flexGrow={1000} id="waveform-container"></Box>
    </Box>
  );
}
