import { create } from 'zustand';

type TokenState = {
  token: string;
  isLoading: boolean;
  isFinished: boolean;
  isUserExist: boolean;
};

type TokenActions = {
  setLoading: (loading: boolean) => void;
  setToken: (token: string) => void;
  setFinished: (finished: boolean) => void;
  setIsUserExist: (isUserExist: boolean) => void;
};

export const useTokenStore = create<TokenState & TokenActions>(set => ({
  token: '',
  isFinished: false,
  isLoading: false,
  isUserExist: false,
  setLoading: loading => {
    set({ isLoading: loading });
  },
  setToken: token => {
    set({ token });
  },
  setFinished: finished => set({ isFinished: finished }),
  setIsUserExist: isUserExist => set({ isUserExist })
}));
