import { SongMetadata } from '../@types/songMetadata';
import { AudioFile, MoveSongRequest, Song, SongAccessData, SongVersion } from '../@types/songs';
import { CreateParentSongResponse, UploadFileRequest, UploadFileResponse } from '../@types/uploadFile';
import axios from '../utils/axios';

const getSong = async (
  versionId: string,
  originalStems: boolean,
  fetchNoneUploaded: boolean
): Promise<{
  song: AudioFile;
  stems: AudioFile[];
  bpm: number;
  isPublic: boolean;
  description: string;
  songParentId: string;
  key: string;
  metadata: SongMetadata;
  isVersionMode: boolean;
}> => {
  return await axios
    .get<{
      song: AudioFile;
      stems: AudioFile[];
      bpm: number;
      isPublic: boolean;
      description: string;
      songParentId: string;
      key: string;
      metadata: SongMetadata;
      isVersionMode: boolean;
    }>(`songs/${versionId}/files?isOriginalStems=${originalStems}&fetchNoneUploaded=${fetchNoneUploaded}`)
    .then(res => res.data);
};

const reorderStem = async (trackId: string, newOrder: number, version: string) => {
  await axios.post(`songs/reorder-stem`, { trackId, newOrder, version });
};

const sendAccessRequestEmail = async (songId: string, folderId: string) => {
  await axios.post(`songs/send-request-email`, { songId, folderId });
};

const getSongAccessData = async (songId: string): Promise<SongAccessData> => {
  return await axios.get<SongAccessData>(`songs/song-access-data/${songId}`).then(res => res.data);
};

const getSongVersions = async (songId: string): Promise<SongVersion[]> => {
  return await axios.get<SongVersion[]>(`songs/${songId}/versions`).then(res => res.data);
};

const addSongVersion = async (songId: string, songName: string, description: string): Promise<SongVersion> => {
  return await axios.post<SongVersion>(`songs/${songId}/addVersion`, { songName, description }).then(res => res.data);
};

const deleteSongByVersion = async (versionId: string): Promise<void> => {
  await axios.delete(`songs/deleteVersion/${versionId}`);
};

const deleteSong = async (songId: string): Promise<void> => {
  await axios.delete(`songs/deleteSong/${songId}`);
};

const createParentSong = async (folderId: string, songId: string, name: string): Promise<CreateParentSongResponse> => {
  return await axios.post<CreateParentSongResponse>('upload/create-parent-song', { folderId, songId, name }).then(res => res.data);
};

const createPresignedPost = async (file: UploadFileRequest): Promise<UploadFileResponse> => {
  return await axios.post<UploadFileResponse>('upload/create-presigned-post', file).then(res => res.data);
};
const createFile = async (file: UploadFileRequest) => {
  return await axios.post<UploadFileResponse>('upload/create-file', file).then(res => res.data);
};
const moveSong = async (data: MoveSongRequest) => {
  await axios.post(`songs/${data.songId}/move`, { targetProject: data.newFolderId });
};

const renameTrack = async (trackId: string, newName: string) => {
  await axios.post(`songs/rename-track`, { trackId, newName });
};

const renameVersionWithoutSongTrack = async (versionId: string, newName: string) => {
  await axios.post(`songs/rename-version-without-song-track`, { versionId, newName });
};

const editDescription = async (versionId: string, description: string) => {
  await axios.post(`songs/edit-description`, { versionId, description });
};

const editBPM = async (versionId: string, bpm: number) => {
  await axios.post(`songs/edit-bpm`, { versionId, bpm });
};

const editKey = async (versionId: string, key: string) => {
  await axios.post(`songs/edit-key`, { versionId, key });
};
const downloadSong = async (versionId: string, downloadStems: boolean, downloadSong: boolean) => {
  return await axios.post(
    `songs/downloadVersion/${versionId}`,
    {
      downloadStems,
      downloadSong
    },
    { responseType: 'blob' }
  );
};

const deleteStem = async (stemId: string) => {
  return await axios.delete(`songs/deleteStem/${stemId}`);
};

const downloadStem = async (stemId: string) => {
  return await axios.post(`songs/downloadStem`, { stemId }).then(res => res.data);
};

const getSongById = async (songId: string): Promise<Song> => {
  return await axios.get(`songs/${songId}/get-song`).then(res => res.data);
};

const isSongExist = async (songId: string): Promise<boolean> => {
  return await axios.get(`songs/${songId}/is-exist`).then(res => res.data);
};

const changeMultiTrackMode = async (versionId: string, isVersionMode: boolean): Promise<void> => {
  return await axios.post(`songs/change-multi-track-mode/${versionId}`, { isVersionMode });
};

const changeSoloMutedStem = async (stemId: string, solo: boolean, muted: boolean): Promise<void> => {
  return await axios.post(`songs/change-solo-muted/${stemId}`, { solo, muted });
};

const songsService = {
  getSong,
  getSongVersions,
  addSongVersion,
  deleteSongByVersion,
  deleteSong,
  createParentSong,
  createPresignedPost,
  getSongAccessData,
  moveSong,
  createFile,
  downloadSong,
  deleteStem,
  downloadStem,
  editDescription,
  editBPM,
  editKey,
  renameTrack,
  reorderStem,
  renameVersionWithoutSongTrack,
  getSongById,
  sendAccessRequestEmail,
  isSongExist,
  changeMultiTrackMode,
  changeSoloMutedStem
};

export default songsService;
