import { defaultFolderName, useFoldersStore } from '../folders.store';

export function useFoldersSelector() {
  // State
  const folders = useFoldersStore(state => state.folders.filter(folder => folder.name !== defaultFolderName));
  const sharedFolderUsers = useFoldersStore(state => state.sharedFolderUsers);
  const foldersError = useFoldersStore(state => state.foldersError);
  const isFoldersLoading = useFoldersStore(state => state.isFoldersLoading);
  const isRenameLoading = useFoldersStore(state => state.isRenameLoading);
  const defaultFolder = useFoldersStore(state => state.folders.find(folder => folder.name === defaultFolderName));

  // Actions
  const getFolders = useFoldersStore(state => state.getFolders);
  const createFolder = useFoldersStore(state => state.createFolder);
  const deleteFolder = useFoldersStore(state => state.deleteFolder);
  const renameFolder = useFoldersStore(state => state.renameFolder);
  const createSongInFolder = useFoldersStore(state => state.createSongInFolder);
  const deleteFolderSong = useFoldersStore(state => state.deleteFolderSong);
  const moveFolderSong = useFoldersStore(state => state.moveFolderSong);
  const renameSongInSidebar = useFoldersStore(state => state.renameSongInSidebar);
  const renameSong = useFoldersStore(state => state.renameSong);
  const shareFolderByInvite = useFoldersStore(state => state.shareFolderByInvite);
  const getFolderSharedUsers = useFoldersStore(state => state.getFolderSharedUsers);
  const toggleFolder = useFoldersStore(state => state.toggleFolder);
  const deleteFolderSharedUser = useFoldersStore(state => state.deleteFolderSharedUser);
  const setFolders = useFoldersStore(state => state.setFolders);
  const openFolder = useFoldersStore(state => state.openFolder);
  const moveFolder = useFoldersStore(state => state.moveFolder);
  const moveToRootFolder = useFoldersStore(state => state.moveToRootFolder);

  return {
    defaultFolder,
    folders,
    foldersError,
    isFoldersLoading,
    isRenameLoading,
    sharedFolderUsers,
    getFolders,
    createFolder,
    deleteFolder,
    renameFolder,
    createSongInFolder,
    deleteFolderSong,
    moveFolderSong,
    renameSongInSidebar,
    renameSong,
    shareFolderByInvite,
    getFolderSharedUsers,
    deleteFolderSharedUser,
    toggleFolder,
    setFolders,
    openFolder,
    moveFolder,
    moveToRootFolder
  };
}
