import { DropResult } from 'react-beautiful-dnd';
import { create } from 'zustand';
import { ShareByInviteRequest, SharedUserInfo } from '../@types/sharing';
import { MetadataBitDepthValues, MetadataSampleRateValues, SongMetadata } from '../@types/songMetadata';
import { AudioFile, SongVersion } from '../@types/songs';
import { stemToUpload, uploadFile } from '../@types/uploadFile';
import sharingService from '../services/sharingService';
import songMetadataService from '../services/songMetadataService';
import songsService from '../services/songsService';

type SongStoreState = {
  songParentId?: string;
  description?: string;
  createdAt?: string;
  updatedAt?: string;
  uploadedBy?: string;
  key?: string;
  bpm?: number;
  song: AudioFile | null;
  stems: AudioFile[];
  metadata: SongMetadata;
  versions: SongVersion[];
  currentVersionId: string | null;
  sharedUsers: SharedUserInfo[];
  preparedSharedUsers: SharedUserInfo[];
  songError: Error | string | null;
  isSongLoading: boolean;
  isDownloadingFiles: boolean;
  isDeletingSong: boolean;
  isUploading: boolean;
  isNewSong: boolean;
  accessShareType: string;
  inputShareType: string;
  accessTypePublic: string;
  isOriginalStems: boolean;
  isCompressedExists: boolean;
};

export const navigationSongCache: (SongStoreState & { songId: string })[] = [];

type SongStoreActions = {
  getFromNavigationCache: (songId: string) => SongStoreState | undefined;
  setIsNewSong: (isNewSong: boolean) => void;
  clearState: () => void;
  clearSong: () => void;
  clearStems: () => void;
  setIsOriginalStems: (isOriginalStems: boolean) => void;
  setCachedFiles: (
    song: AudioFile | null,
    stems: AudioFile[],
    metadata: SongMetadata,
    songParentId?: string,
    description?: string,
    bpm?: number
  ) => void;
  createNewSong: (
    song: AudioFile | null,
    stems: AudioFile[],
    versions: SongVersion[],
    currentVersionId: string,
    songParentId?: string,
    description?: string,
    createdAt?: string,
    updatedAt?: string,
    uploadedBy?: string,
    bpm?: number,
    preparedSharedUsers?: SharedUserInfo[]
  ) => void;
  setSong: (song: AudioFile) => void;
  getSongVersions: (songId: string) => Promise<string | undefined>;
  getSongWithStems: (
    uploadingStems?: stemToUpload[],
    uploadingSong?: uploadFile | null,
    getOriginalStems?: boolean
  ) => Promise<{
    song: AudioFile;
    stems: AudioFile[];
    isVersionMode: boolean;
    bpm?: number;
    description?: string;
    createdAt?: string;
    updatedAt?: string;
    uploadedBy?: string;
    songParentId?: string;
    key?: string;
  }>;

  //
  getSongStems: () => Promise<{
    song: AudioFile;
    stems: AudioFile[];
    bpm?: number;
    description?: string;
    createdAt?: string;
    updatedAt?: string;
    uploadedBy?: string;
    songParentId?: string;
    key?: string;
  }>;
  //

  downloadFiles: (version: string, downloadStems: boolean, downloadSong: boolean) => Promise<void>;
  downloadStem: (stemId: string, stemName: string) => Promise<void>;
  deleteSong: (songId: string) => Promise<void>;
  deleteStem: (stemId: string) => Promise<void>;
  addVersion: (songId: string, songName: string, description: string) => Promise<SongVersion | undefined>;
  changeVersion: (versionId: string) => Promise<string>;
  deleteVersion: (versionId: string) => Promise<void>;
  getSharedUsers: (songId: string) => Promise<void>;
  inviteUser: (data: ShareByInviteRequest) => Promise<void>;
  addStems: (stems: AudioFile[]) => void;
  deleteSharedUser: (email: string, songId: string) => Promise<void>;
  renameSong: (newName: string) => Promise<void>;
  renameSongOnMainSongPage: (newName: string, currentVersion: string) => void;
  editDescription: (description: string) => Promise<void>;
  editKey: (key: string) => Promise<void>;
  editBPM: (bpm: number) => Promise<void>;
  renameStem: (stemId: string, newName: string) => Promise<void>;
  renameVersionWithoutSongTrack: (newName: string, version?: string) => Promise<void>;
  reorderStems: (dropResult: DropResult) => Promise<void>;
  addAlbumTitle: (versionId: string, albumTitle: string) => Promise<void>;
  addGenre: (versionId: string, genre: string) => Promise<void>;
  addArtist: (versionId: string, name: string) => Promise<void>;
  addPublisher: (versionId: string, name: string, ownershipRate: number) => Promise<void>;
  addComposer: (versionId: string, name: string, ownershipRate: number) => Promise<void>;
  addISRCCode: (versionId: string, code: string) => Promise<void>;
  addUPCCode: (versionId: string, code: string) => Promise<void>;
  addEditLyrics: (versionId: string, lyrics: string) => Promise<void>;
  deleteAlbumTitle: (albumTitleId: string) => Promise<void>;
  deleteGenre: (genreId: string) => Promise<void>;
  deleteArtist: (artistId: string) => Promise<void>;
  deleteComposer: (composerId: string) => Promise<void>;
  deletePublisher: (publisherId: string) => Promise<void>;
  deleteISRCCode: (ISRCCodeId: string) => Promise<void>;
  deleteUPCCode: (UPCCodeId: string) => Promise<void>;
  changeSampleRate: (sampleRate: MetadataSampleRateValues) => Promise<void>;
  changeBitDepth: (bitDepth: MetadataBitDepthValues) => Promise<void>;
  setAccessShareType: (accessShareType: string) => void;
  setAccessTypePublic: (accessTypePublic: string) => void;
  addPreparedSharedUser: (email: string, accessType: string) => void;
  deletePreparedSharedUser: (email: string) => void;
  setInputShareType: (accessType: string) => void;
  changeMultiTrackMode: (versionId: string, isVersionMode: boolean) => Promise<void>;
  changeSoloMutedStem: (stemId: string, solo: boolean, muted: boolean) => Promise<void>;
};

export const useSongStore = create<SongStoreState & SongStoreActions>((set, get) => ({
  isNewSong: false,
  song: null,
  isCompressedExists: false,
  stems: [],
  bpm: 0,
  key: 'A',
  metadata: {
    albumTitles: [],
    genres: [],
    artists: [],
    publishers: [],
    composers: [],
    ISRCCodes: [],
    UPCCodes: [],
    lyrics: [],
    sampleRate: MetadataSampleRateValues.FortyFourPointOne,
    bitDepth: MetadataBitDepthValues.Sixteen
  },
  versions: [],
  currentVersionId: null,
  sharedUsers: [],
  songError: null,
  isSongLoading: false,
  isDownloadingFiles: false,
  isDeletingSong: false,
  isUploading: false,
  accessShareType: 'anyone',
  accessTypePublic: 'CO_OWNER',
  inputShareType: 'CO_OWNER',
  preparedSharedUsers: [],
  isOriginalStems: false,
  setIsNewSong: isNewSong => {
    set({
      isNewSong
    });
  },
  setSong: song => {
    set({ song });
  },
  getFromNavigationCache: songId => {
    return navigationSongCache.find(song => song.songId === songId);
  },
  updateNavigationCache: (songId: string) => {
    navigationSongCache.push({ songId, ...get() });
    if (navigationSongCache.length > 3) {
      navigationSongCache.shift();
    }
  },
  clearState: () =>
    set({
      song: null,
      stems: [],
      versions: [],
      currentVersionId: null,
      sharedUsers: [],
      songError: null,
      isSongLoading: false,
      isDownloadingFiles: false,
      isDeletingSong: false,
      isUploading: false,
      isNewSong: false,
      preparedSharedUsers: [],
      isCompressedExists: false,
      inputShareType: 'CO_OWNER'
    }),
  clearSong: () => set({ song: null }),
  clearStems: () => {
    set({ stems: [] });
  },
  reorderStems: async (dropResult: DropResult) => {
    if (dropResult.destination?.index === dropResult.source?.index) {
      return;
    }

    const version = get().currentVersionId;
    const stems = [...get().stems];
    const sourceStem = stems[dropResult.source.index];
    let newOrder = 0;
    if ((dropResult.destination?.index ?? 0) === stems.length - 1) {
      newOrder = (stems[dropResult.destination?.index ?? 0]?.order ?? 0) + 1;
    } else if ((dropResult.destination?.index ?? 0) === 0) {
      newOrder = (stems[dropResult.destination?.index ?? 0]?.order ?? 0) / 2;
    } else if ((dropResult.destination?.index ?? 0) < dropResult.source?.index) {
      const newAfterStemOrder = stems[dropResult.destination?.index ?? 0]?.order ?? 0;
      const newPreviousStemOrder = stems[(dropResult.destination?.index ?? 0) - 1]?.order ?? 0;
      newOrder = (newPreviousStemOrder + newAfterStemOrder) / 2;
      newOrder = (newPreviousStemOrder + newAfterStemOrder) / 2;
    } else {
      const newAfterStemOrder = stems[(dropResult.destination?.index ?? 0) + 1]?.order ?? 0;
      const newPreviousStemOrder = stems[dropResult.destination?.index ?? 0]?.order ?? 0;
      newOrder = (newPreviousStemOrder + newAfterStemOrder) / 2;
    }

    if (version) {
      songsService.reorderStem(sourceStem.id, newOrder, version);
    }

    stems.splice(dropResult.source.index, 1);
    stems.splice(dropResult.destination?.index ?? 0, 0, { ...sourceStem, order: newOrder });
    set({ stems });
  },
  setCachedFiles: (song, stems, metadata, songParentId, description, bpm) => {
    set({ song, stems, metadata, songParentId, description, bpm });
  },
  createNewSong: (
    song,
    stems,
    versions,
    currentVersionId,
    songParentId,
    description,
    createdAt,
    updatedAt,
    uploadedBy,
    bpm,
    preparedSharedUsers
  ) => {
    set({
      song,
      stems,
      versions,
      currentVersionId,
      songParentId,
      description,
      createdAt,
      updatedAt,
      uploadedBy,
      isNewSong: true,
      bpm,
      isCompressedExists: false,
      preparedSharedUsers,
      metadata: {
        albumTitles: [],
        genres: [],
        artists: [],
        publishers: [],
        composers: [],
        ISRCCodes: [],
        UPCCodes: [],
        lyrics: [],
        sampleRate: MetadataSampleRateValues.FortyFourPointOne,
        bitDepth: MetadataBitDepthValues.Sixteen
      }
    });
  },
  getSongVersions: async songId => {
    try {
      const versions = await songsService.getSongVersions(songId);
      const currentVersionId = versions?.at(versions.length - 1)?.id;
      set({ versions: [...versions], currentVersionId: currentVersionId, songError: null });
      return currentVersionId;
    } catch (e: any) {
      set({ versions: [], currentVersionId: null, songError: e.message, song: null, isSongLoading: false });
    }
  },
  setIsOriginalStems: isOriginalStems => {
    set({ isOriginalStems });
  },
  getSongWithStems: async (uploadingStems?: stemToUpload[], uploadingSong?: uploadFile | null, getOriginalStems?: boolean) => {
    const currentVersion = get().currentVersionId;
    const isOriginalStems = get().isOriginalStems || !!getOriginalStems;
    if (currentVersion) {
      try {
        set({ isSongLoading: true });

        const files = await songsService.getSong(currentVersion, isOriginalStems, !!uploadingStems);

        if (uploadingStems) {
          files.stems = files.stems.map(stem => {
            const uploadingStem = uploadingStems?.find(uploadedStem => uploadedStem.stemId === stem.id);

            if (uploadingStem) {
              return { ...stem, url: URL.createObjectURL(uploadingStem.file) };
            }
            return stem;
          });
          if (uploadingSong) {
            files.song.url = URL.createObjectURL(uploadingSong?.file!);
          }
        }
        set({ ...files, isSongLoading: false, songError: null, isNewSong: false });

        return files;
      } catch (e: any) {
        set({ song: null, isSongLoading: false, songError: e.message, isNewSong: false });
      }
    }

    return { song: {} as AudioFile, stems: [], isVersionMode: false };
  },
  getSongStems: async () => {
    const currentVersion = get().currentVersionId;

    if (currentVersion) {
      try {
        set({ isSongLoading: true });

        const files = await songsService.getSong(currentVersion, get().isOriginalStems, false);

        set({ ...files, isSongLoading: false, songError: null, isNewSong: false });

        return files;
      } catch (e: any) {
        set({ song: null, isSongLoading: false, songError: e.message, isNewSong: false });
      }
    }

    return { song: {} as AudioFile, stems: [] };
  },
  downloadFiles: async (versionId, downloadStems, downloadSong) => {
    try {
      set({ isDownloadingFiles: true });

      const response = await songsService.downloadSong(versionId, downloadStems, downloadSong);

      const blob = new Blob([response.data], { type: 'application/zip' });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${get().song?.name.replace(/\.[^/.]+$/, '')}.zip`;

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    } catch (e: any) {
      set({ songError: e.message });
    } finally {
      set({ isDownloadingFiles: false });
    }
  },
  downloadStem: async (stemId, stemName) => {
    try {
      set({ isDownloadingFiles: true });

      const { url } = await songsService.downloadStem(stemId);
      const response = await fetch(url);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(await response.blob());

      link.download = stemName;
      document.body.appendChild(link);

      link.click();
      document.body.removeChild(link);
    } catch (e: any) {
      set({ songError: e.message });
    } finally {
      set({ isDownloadingFiles: false });
    }
  },
  deleteSong: async songId => {
    try {
      set({ isDeletingSong: true });

      await songsService.deleteSong(songId);

      get().clearState();
    } catch (e: any) {
      set({ songError: e.message, isDeletingSong: false });
    }
  },
  deleteStem: async stemId => {
    try {
      await songsService.deleteStem(stemId);

      set({ stems: get().stems.filter(s => s.id !== stemId) });
    } catch (e: any) {
      set({ songError: e.message });
    }
  },
  addVersion: async (songId, songName, description) => {
    try {
      const newVersion = await songsService.addSongVersion(songId, songName, description);

      set({ versions: [...get().versions, { ...newVersion, createdAt: new Date().toString() }] });

      return newVersion;
    } catch (e: any) {}
  },
  changeVersion: async versionId => {
    set({ currentVersionId: versionId, stems: [], song: null });
    return versionId;
  },
  deleteVersion: async versionId => {
    try {
      await songsService.deleteSongByVersion(versionId);

      const filteredVersions = get().versions.filter(v => v.id !== versionId);

      set({ versions: [...filteredVersions] });
    } catch (e) {}
  },
  getSharedUsers: async songId => {
    try {
      const sharedUsers = await sharingService.getSharedUsersBySongId(songId);

      set({ sharedUsers, songError: null });
    } catch (e: any) {
      set({ sharedUsers: [], songError: e.message });
    }
  },
  inviteUser: async data => {
    try {
      await sharingService.shareByInvite(data);

      set({ preparedSharedUsers: [] });

      await get().getSharedUsers(data.songId);
    } catch (e: any) {
      set({ songError: e.message });
    }
  },
  deleteSharedUser: async (email: string, songId: string) => {
    try {
      await sharingService.deleteSharedUser(email, songId);

      set({ sharedUsers: get().sharedUsers.filter(sharedUser => sharedUser.email !== email) });
    } catch (e: any) {
      set({ songError: e.message });
    }
  },
  addStems: stems => {
    set({ stems: [...get().stems, ...stems] });
  },
  renameSong: async newName => {
    try {
      const song = get().song as AudioFile;

      set({
        song: { ...get().song, name: newName } as AudioFile,
        versions: get().versions.map(v => {
          if (v.id === get().currentVersionId) {
            v.songName = newName;
          }

          return v;
        })
      });

      await songsService.renameTrack(song.id, newName);
    } catch (e: any) {
      set({ songError: e.message });
    }
  },
  renameSongOnMainSongPage: (newName, currentVersion) => {
    set({
      song: { ...get().song, name: newName } as AudioFile,
      versions: get().versions.map(v => {
        if (v.id === currentVersion) {
          v.songName = newName;
        }

        return v;
      })
    });
  },
  editDescription: async description => {
    try {
      set({ description });

      await songsService.editDescription(get()?.currentVersionId ?? '', description ?? '');
    } catch (e: any) {
      set({ songError: e.message });
    }
  },
  editBPM: async (bpm: number) => {
    try {
      set({ bpm });

      await songsService.editBPM(get()?.currentVersionId ?? '', bpm ?? 0);
    } catch (e: any) {
      set({ songError: e.message });
    }
  },
  editKey: async (key: string) => {
    try {
      set({ key });

      await songsService.editKey(get()?.currentVersionId ?? '', key ?? '');
    } catch (e: any) {
      set({ songError: e.message });
    }
  },
  renameStem: async (stemId, newName) => {
    try {
      const song = get().song as AudioFile;

      set({
        stems: get().stems.map(stem => {
          if (stem.id === stemId) {
            return { ...stem, name: newName };
          }

          return stem;
        })
      });

      await songsService.renameTrack(stemId, newName);
    } catch (e: any) {
      set({ songError: e.message });
    }
  },
  renameVersionWithoutSongTrack: async (newName, version) => {
    try {
      set({
        versions: get().versions.map(v => {
          if (v.id === (version ?? get().currentVersionId)) {
            v.songName = newName;
          }

          return v;
        })
      });

      await songsService.renameVersionWithoutSongTrack(version ?? get().currentVersionId!, newName);
    } catch (e: any) {
      set({ songError: e.message });
    }
  },
  addAlbumTitle: async (versionId, albumTitle) => {
    try {
      const albumId = await songMetadataService.addAlbumTitle(versionId, albumTitle);

      set({ metadata: { ...get().metadata, albumTitles: [...get().metadata.albumTitles, { id: albumId, albumTitle }] } });
    } catch (e: any) {
      set({ songError: e.message });
    }
  },
  addGenre: async (versionId, genre) => {
    try {
      const genreId = await songMetadataService.addGenre(versionId, genre);

      set({ metadata: { ...get().metadata, genres: [...get().metadata.genres, { id: genreId, genre }] } });
    } catch (e: any) {
      set({ songError: e.message });
    }
  },
  addArtist: async (versionId, name) => {
    try {
      const artistId = await songMetadataService.addArtist(versionId, name);

      set({ metadata: { ...get().metadata, artists: [...get().metadata.artists, { id: artistId, name }] } });
    } catch (e: any) {
      set({ songError: e.message });
    }
  },
  addPublisher: async (versionId, name, ownershipRate) => {
    try {
      const publisherId = await songMetadataService.addPublisher(versionId, name, ownershipRate);

      set({ metadata: { ...get().metadata, publishers: [...get().metadata.publishers, { id: publisherId, name, ownershipRate }] } });
    } catch (e: any) {
      set({ songError: e.message });
    }
  },
  addComposer: async (versionId, name, ownershipRate) => {
    try {
      const composerId = await songMetadataService.addComposer(versionId, name, ownershipRate);

      set({ metadata: { ...get().metadata, composers: [...get().metadata.composers, { id: composerId, name, ownershipRate }] } });
    } catch (e: any) {
      set({ songError: e.message });
    }
  },
  addISRCCode: async (versionId, code) => {
    try {
      const codeId = await songMetadataService.addISRCCode(versionId, code);

      set({ metadata: { ...get().metadata, ISRCCodes: [...get().metadata.ISRCCodes, { id: codeId, code }] } });
    } catch (e: any) {
      set({ songError: e.message });
    }
  },
  addUPCCode: async (versionId, code) => {
    try {
      const codeId = await songMetadataService.addUPCCode(versionId, code);

      set({ metadata: { ...get().metadata, UPCCodes: [...get().metadata.UPCCodes, { id: codeId, code }] } });
    } catch (e: any) {
      set({ songError: e.message });
    }
  },
  addEditLyrics: async (versionId, lyrics) => {
    try {
      const lyricsId = await songMetadataService.addEditLyrics(versionId, lyrics);

      set({ metadata: { ...get().metadata, lyrics: [{ id: lyricsId, lyrics }] } });
    } catch (e: any) {
      set({ songError: e.message });
    }
  },
  deleteAlbumTitle: async albumTitleId => {
    try {
      set({ metadata: { ...get().metadata, albumTitles: get().metadata.albumTitles.filter(album => album.id !== albumTitleId) } });

      await songMetadataService.deleteAlbumTitle(albumTitleId);
    } catch (e: any) {
      set({ songError: e.message });
    }
  },
  deleteGenre: async genreId => {
    try {
      set({ metadata: { ...get().metadata, genres: get().metadata.genres.filter(genre => genre.id !== genreId) } });

      await songMetadataService.deleteGenre(genreId);
    } catch (e: any) {
      set({ songError: e.message });
    }
  },
  deleteArtist: async artistId => {
    try {
      set({ metadata: { ...get().metadata, artists: get().metadata.artists.filter(artist => artist.id !== artistId) } });

      await songMetadataService.deleteArtist(artistId);
    } catch (e: any) {
      set({ songError: e.message });
    }
  },
  deleteComposer: async composerId => {
    try {
      set({ metadata: { ...get().metadata, composers: get().metadata.composers.filter(composer => composer.id !== composerId) } });

      await songMetadataService.deleteComposer(composerId);
    } catch (e: any) {
      set({ songError: e.message });
    }
  },
  deletePublisher: async publisherId => {
    try {
      set({ metadata: { ...get().metadata, publishers: get().metadata.publishers.filter(publisher => publisher.id !== publisherId) } });

      await songMetadataService.deletePublisher(publisherId);
    } catch (e: any) {
      set({ songError: e.message });
    }
  },
  deleteISRCCode: async ISRCCodeId => {
    try {
      set({ metadata: { ...get().metadata, ISRCCodes: get().metadata.ISRCCodes.filter(code => code.id !== ISRCCodeId) } });

      await songMetadataService.deleteISRCCode(ISRCCodeId);
    } catch (e: any) {
      set({ songError: e.message });
    }
  },
  deleteUPCCode: async UPCCodeId => {
    try {
      set({ metadata: { ...get().metadata, UPCCodes: get().metadata.UPCCodes.filter(code => code.id !== UPCCodeId) } });

      await songMetadataService.deleteUPCCode(UPCCodeId);
    } catch (e: any) {
      set({ songError: e.message });
    }
  },
  changeSampleRate: async sampleRate => {
    try {
      set({ metadata: { ...get().metadata, sampleRate } });

      await songMetadataService.changeSampleRate(get().currentVersionId!, sampleRate);
    } catch (e: any) {
      set({ songError: e.message });
    }
  },
  changeBitDepth: async bitDepth => {
    try {
      set({ metadata: { ...get().metadata, bitDepth } });

      await songMetadataService.changeBitDepth(get().currentVersionId!, bitDepth);
    } catch (e: any) {
      set({ songError: e.message });
    }
  },
  setAccessShareType: accessShareType => {
    set({ accessShareType });
  },
  setAccessTypePublic: accessTypePublic => {
    set({ accessTypePublic });
  },
  addPreparedSharedUser: (email, accessType) => {
    set({ preparedSharedUsers: [...get().preparedSharedUsers, { email, accessType }] });
  },
  deletePreparedSharedUser: email => {
    set({ preparedSharedUsers: get().preparedSharedUsers.filter(u => u.email !== email) });
  },
  setInputShareType: accessType => {
    set({ inputShareType: accessType });
  },
  changeMultiTrackMode: async (versionId, isVersionMode) => {
    await songsService.changeMultiTrackMode(versionId, isVersionMode);
  },
  changeSoloMutedStem: async (stemId, solo, muted) => {
    await songsService.changeSoloMutedStem(stemId, solo, muted);
  }
}));
