import { TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useSongSelector } from '../../store/selectors/song.selector';

export type SongDescriptionProps = {
  canEdit: boolean;
};

export function SongDescription({ canEdit }: SongDescriptionProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { description, editDescription } = useSongSelector();

  const [inputDescription, setInputDescription] = useState('');
  const editDescriptionRef = useRef<null | HTMLInputElement>(null);

  useEffect(() => {
    if (description) {
      setInputDescription(description);
    } else {
      setInputDescription('');
    }
  }, [description]);

  const handleOnChangeOnEditDescription = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    setInputDescription(input);
  };

  const handleKeyDownOnEditDescription = async (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === 'Escape') {
      e.preventDefault();
      editDescriptionRef.current?.blur();
    }
  };

  return (
    <TextField
      id="outlined-textarea"
      variant="standard"
      multiline
      maxRows={4}
      value={inputDescription}
      fullWidth
      inputRef={editDescriptionRef}
      onKeyDown={handleKeyDownOnEditDescription}
      disabled={!canEdit}
      onBlur={e => {
        if (description !== inputDescription) {
          editDescription(e.target.value);
          enqueueSnackbar('Notes edited successfully', { variant: 'success' });
        }
      }}
      onChange={handleOnChangeOnEditDescription}
      placeholder="Notes"
      sx={{
        '& .MuiInputBase-input': {
          maxWidth: '450px',
          fontSize: '16px',
          padding: '0',
          border: 'none',
          outline: 'none',
          boxShadow: 'none',
          backgroundColor: 'transparent',
          color: '#FFFF',
          lineHeight: '1.5',
          transition: 'none',
          caretColor: '#FFFF'
        },
        '.MuiInput-underline:before, .MuiInput-underline:after': {
          borderBottom: 'none !important'
        },
        '.MuiInput-underline:hover:not(.Mui-disabled):before': {
          borderBottom: 'none !important'
        },
        '.MuiInput-underline:focus:before': {
          borderBottom: 'none !important'
        }
      }}
    />
  );
}
